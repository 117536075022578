.border-bottom {
    border-bottom: 2px solid #262626;
}

.borderlight-bottom {
    border-bottom: 1px solid #4C5160;
}

.border {
    border: 2px solid #262626;
}

.border-radius {
    border-radius: 8px;
}


.border-right {
    border-right: 2px solid #262626;
}

.rounded-lg {
    border-radius: 8px;
}

.borderlight-right {
    border-right: 2px solid #45464B;
}
