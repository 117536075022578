.overflow-hidden {
    overflow: hidden;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-decoration-none {
    text-decoration: none;
    color: inherit;
}

.page-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 40px;
    padding-left: 40px;
}

@media only screen and (max-width: 768px) {
    .page-container {
        padding-right: 12px;
        padding-left: 12px;
    }
}


.tooltip-wrapper {
    font-size: 14px !important;
    font-family: inherit;
    text-transform: capitalize;
    background-color: #141414 !important;
    border: 2px solid #262626 !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    z-index: 100;
}

.tooltip-arrow {
    background-color: #262626 !important;
}

.airdrop-tooltip {
    font-size: 14px !important;
    font-family: inherit;
    z-index: 100;
    padding: 8px 12px !important;
    max-width: 300px;
    opacity: 1 !important;
    border-radius: 6px !important;
    background-color: #1B1B1B !important;
    color: #E6E6E6 !important;
    font-weight: 500;
    line-height: 1.375;
    text-align: center;
}

.airdrop-tooltiparrow{
    background-color: red !important;
    
}

.vertical-middle {
    vertical-align: middle;
}

.avatar-md {
    height: 40px;
    width: 40px;
    vertical-align: middle;
    border-radius: 4px;
}