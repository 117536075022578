#profile-description {
  position: relative;
}
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}
.mapboxgl-popup-close-button {
  padding: 0px 8px;
  font-size: 2rem;
  background-color: #f6c946;
}
.text-dark-light {
  color: #6c6c6c;
}
#profile-description .text {
  margin-bottom: 5px;
  position: relative;
  display: block;
}
#profile-description .show-more {
  width: 100%;
  color: #777;
  position: relative;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.13);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #f6c946;
  padding: 5px;
  margin-bottom: 10px;
}
#profile-description .show-more-height {
  height: 65px;
  overflow: hidden;
}
.tf-section.tf-item-details,
.tf-section.top-seller.style-2,
.tf-section.today-pick,
.tf-section.authors,
.tf-section.tf-rank {
  padding: 50px 0px 59px;
}

.rounded-1 {
  border-radius: 10px;
}
.rounded-2 {
  border-radius: 20px;
}
.rounded-3 {
  border-radius: 30px;
}
.news-count {
  position: absolute;
  font-size: 13px;
  background: #d3ab41;
  color: #000000;
  border-radius: 50px;
  height: 8px;
  width: 8px;
  line-height: 0;
  padding: 11px;
  display: flex;
  top: 21px;
  justify-content: center;
  align-items: center;
  right: -5px;
  font-weight: bold;
  z-index: 99;
}
.search-dropdown {
  position: absolute;
  z-index: 9;
  background: #ffffff;
  right: 0;
  left: 0;
  border-radius: 2px;
  -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.search-top-col {
  font-size: 3rem;
  vertical-align: baseline;
  margin-top: -15px;
}
.search-dropdown li h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.search-dropdown li h5 a {
  color: #000000;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5;
}
.search-dropdown li {
  padding: 10px 10px;
  border-bottom: 1px solid #cccccc7d;
  display: flex;
  align-items: center;
}
.search-dropdown li img {
  width: 35px;
  border-radius: 50%;
  height: 35px;
  margin-right: 8px;
}

.search-in-home .portfolio-search input {
  width: 250px !important;
}

.mobile-search-collection {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: #000000ed;
  -webkit-animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}
@keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

.Search_input {
  background: none;
  outline: none;
  border: none;
  font-size: 24px;

  color: white;
  width: 100%;
  padding: 25px;
  text-transform: uppercase;
  width: 80% !important;
}
.mobile-search-collection svg {
  position: absolute;
  top: 2.8rem;
  right: 12px;
  z-index: 99999;
}
.author-avatar .lazy-load-image-background.blur {
  height: 44px !important;
}
@keyframes animateIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}
.forMobile {
  margin-top: 2rem;
}
.search-dropdown li {
  cursor: pointer;
}

/* loaded by iframe_inject.js */

.popup {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
}

.popup .popup_panel {
  width: fit-content;
  min-height: 100px;
  border-radius: 10px;
  background-color: #fff;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  height: 590px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 20px);
}

.nftpay_iframe {
  max-width: 320px;
  height: calc(590px + 10px);
}

.avatartack img {
  border-radius: 50%;
  position: relative;
  left: -5px;
  margin-left: -17px;
  z-index: 1;
  width: 25px;
  height: 25px;
  border: 1px solid #ffffff;
}

.avatarsstack {
  direction: rtl; /* This is to get the stack with left on top */
  text-align: center; /* Now need to explitly align left */
  padding-left: 25px; /* Same value as the negative margin */
}
.writingVertical {
  writing-mode: tb-rl;
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(187 187 187 / var(--tw-text-opacity));
}

.search-icon {
  position: absolute;
  top: 1.7rem;
  right: 1.5rem;
  font-size: 2rem;
}

.track-search input::placeholder {
  /* Most modern browsers support this now. */
  color: #fffafa1f;
  font-weight: 600;
}

.inner-post-member li {
  list-style-type: disc !important;
  font-size: 1.4rem;
}

.inner-post-member ul {
  padding-left: 2.5rem;
}

.inner-post-member li:before {
  content: '';
  display: list-item;
  position: absolute;
}

#wallet-address {
  background: transparent;
  border: 1.15758px solid #818181;
  border-radius: 19px;
  font-weight: 600;
  font-size: 14px;
}

.tracker-table-wallet {
  border-collapse: separate;
  border-spacing: 0 2em;
}
.tracker-table-wallet tr th {
  color: #a9a9b7;
  padding: 1.5rem;
}
.tracker-table-wallet thead {
  border-radius: 8px 8px 0px 0px;
  background: #1e1f25;
}
.tracker-table-wallet th:first-child {
  border-radius: 8px 0px 0px 0px;
}

.tracker-table-wallet th:last-child {
  border-radius: 0px 8px 0px 0px;
}

.tracker-table-wallet td {
  background: #1e1f25;
  padding: 1.5rem;
}

.text-grey {
  color: #bfbfbf;
}

.recent-serch-container {
  border-top: 1.5px solid #2d2d2d;
  border-bottom: 1.5px solid #2d2d2d;
  padding: 15px 0px;
}
.recent-serch-container h4 {
  font-weight: 600;
  font-size: 18px;
}
.delete-btn {
  background: rgba(255, 20, 20, 0.16);
  border: 2px solid #ff0505;
  border-radius: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  padding: 5px 15px;
}

.loading-container {
  top: 0;
  left: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(14.2px);
  -webkit-backdrop-filter: blur(14.2px);
}

.search-loading {
  position: absolute;
  top: 1.8rem;
  right: 0.5rem;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.row-table {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
  grid-template-rows: 100px;
  gap: 10px;
}

.skeleton-table {
  background: #e1e1e1;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.skeleton-table::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
    to right,
    transparent 0%,
    #e8e8e8 50%,
    transparent 100%
  );
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
.ril__toolbarItemChild {
  font-size: 24px;
  font-weight: 600;
}
.ril__captionContent {
  font-size: 24px;
  font-weight: 600;
}
.ril__caption {
  bottom: 10px;
}

.ril__toolbar {
  top: 10px;
}

.bg-mt-20 {
  margin-top: 10rem;
}
.alert-desc span {
  color: #ffffff !important;
}

.loading-pulse {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #000000;
  animation: pulse 1500ms infinite;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-pulse img {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #fbb32f;
  }
  100% {
    box-shadow: 0 0 0 100px #fbb32f01;
  }
}

@media (max-width: 768px) {
  .search-in-home .portfolio-search input {
    width: 100% !important;
  }
  .search-dropdown li h5 a {
    font-size: 1.8rem;
  }
  .forMobile {
    margin-top: 0rem;
  }
  .tf-item-details .content-right .themesflat-tabs li .author-avatar img {
    height: 44px !important;
  }
}
.ownership-img {
  width: 230px;
}

@media only screen and (max-width: 991px) {
  #wallet-address {
    font-size: 11.5px;
  }
  .news-count {
    top: 8px;
    left: 9rem;
    right: unset;
  }
  .ownership-img {
    width: 200px!important;
  }
}
@media only screen and (max-width: 780px) {
  .tracker-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  #profile-description .show-more-height {
    height: 80px;
  }
  .utility-media h2 {
    font-size: 2.5rem;
  }
  .tf-section.tf-item-details,
  .tf-section.top-seller.style-2,
  .tf-section.today-pick,
  .tf-section.authors,
  .tf-section.tf-rank {
    padding: 50px 0px 59px;
  }
}
