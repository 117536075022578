.header-container {
  height: 80px;

  background-color: #181818;
  color: #ffffff;
  z-index: 9999;
  width: 100%;

  &.header-container-fixed {
    border: none;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
    left: 0;
    opacity: 1;
    position: fixed;
    top: -80px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
    z-index: 9999;
  }

  &.header-fixed-top {
    top: 0;
  }

  .nav-wrapper {
    padding: 0rem 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .brand-logo {
      height: 70px;
      width: 70px;
    }

    .header-options {
      display: flex;
      align-items: center;
      // width: calc(100% - 100px);
      justify-content: flex-end;
    }

    .menu-collapsable {
      display: flex;
      align-items: center;
      box-shadow: none;
      // overflow: hidden;

      &.show-menu {
        left: 0;
      }

      &.active-mobile {
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 65%;
      }
    }

    @media only screen and (max-width: 992px) {
      .menu-collapsable {
        flex-direction: column;
        position: fixed;

        background-color: #14141f;
        box-shadow: 0px 3px 16px rgb(47 83 109 / 20%);

        top: 0;
        left: -75%;
        width: 65%;
        height: 100vh;
        overflow-y: auto;
        z-index: 100;
        transition: left ease 0.2s;
      }
    }

    .search-btn {
      background: #000000;
      border: 0.5px solid #ffffff;
      border-radius: 10px;
      padding: 0;
      padding: 10px 0px;
      width: 40px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      .search-icon-header {
        width: 16px;
        height: 16px;
      }
    }

    @media only screen and (max-width: 992px) {
      .search-btn {
        display: none;
      }
    }

    .header-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      // width: 280px;
    }

    .signin-link {
      margin: 0px 16px;
      font-size: 16px;
      white-space: nowrap;
      font-weight: 700;

      &.active,
      &:hover {
        color: #fec60d;
      }
    }

    .mobile-brand-container {
      border-bottom: 1px solid #373746;
      padding: 0 12px;
      display: none;
      width: 100%;

      .brand-logo-mobile {
        height: 70px;
        width: 70px;
      }
    }

    @media only screen and (max-width: 992px) {
      .mobile-brand-container {
        display: flex;
      }
    }

    .menu-options {
      padding: 0;
      margin: 0;
      display: flex;
      // overflow-x: auto;
    }

    @media only screen and (max-width: 992px) {
      .menu-options {
        flex-direction: column;
        width: 100%;
      }
    }

    .menu-item {
      position: relative;
      padding: 8px 12px;

      .menu-link {
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        white-space: nowrap;
        padding: 2px 4px !important;
        display: flex;
        align-items: center;
        position: relative;
        border: 2px solid transparent;

        .menu-icon {
          display: none;
          margin-right: 8px;
        }

        .menu-icon-yellow {
          display: none;
          margin-right: 8px;
        }

        .logout-icon {
          margin-right: 8px;

          &:hover {
            color: #fec60d;
          }
        }

        &.active,
        &:hover {
          color: #fec60d;

          .menu-icon {
            display: none;
          }

          .menu-icon-yellow {
            display: none;
          }
        }
      }

      .label-count {
        position: relative;
        font-size: 13px;
        background: #d3ab41;
        color: #000000;
        border-radius: 50px;
        height: 8px;
        width: 8px;
        line-height: 0;
        padding: 11px;
        display: flex;
        top: -10px;
        justify-content: center;
        align-items: center;
        right: -5px;
        font-weight: bold;
        z-index: 99;
        margin-right: 10px;
      }

      @media only screen and (max-width: 992px) {
        .menu-link {
          font-weight: 500;
          border-bottom: 0;
          align-items: flex-start;
          flex-direction: column;
          .menu-icon {
            display: block;
          }

          &.active,
          &:hover {
            border-bottom: 0px;

            .menu-icon-yellow {
              display: block;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      .menu-item {
        border-bottom: 1px solid #373746;
      }
    }

    .mobile-menu-action {
      display: none;
      width: 100%;

      .search-link-btn {
        padding: 12px 20px;
        border-bottom: 1px solid #373746;
        width: 100%;
        font-size: 16px;

        .search-icon-mobile {
          height: 20px;
          width: 18px;
          margin-right: 8px;
        }
      }

      .auth-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 12px;
      }
    }

    @media only screen and (max-width: 992px) {
      .mobile-menu-action {
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .nav-wrapper {
      padding: 0px 16px;
    }
  }

  %top-bottom-line {
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    top: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 100%;
  }

  .line-class {
    background-color: #fff;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .hamburger-icon {
    display: none;
    background-color: transparent;
    cursor: pointer;
    height: 26px;
    transition: all 0.3s ease;
    top: 50%;
    width: 26px;
    margin-left: 10px;
    position: relative;

    &.active {
      span {
        opacity: 0;
      }

      &::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &::after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    span {
      @extend .line-class;
      height: 3px;
      left: 0;
      overflow: hidden;
      position: absolute;
      text-indent: 200%;
      top: 50%;
      width: 100%;
    }

    &:after {
      @extend .line-class;
      @extend %top-bottom-line;
      -webkit-transform: translate3d(0, 7px, 0);
      -moz-transform: translate3d(0, 7px, 0);
      transform: translate3d(0, 7px, 0);
    }

    &:before {
      @extend .line-class;
      @extend %top-bottom-line;
      transform: translate3d(0, -7px, 0);
    }
  }

  @media only screen and (max-width: 992px) {
    .hamburger-icon {
      display: block;
    }
  }
}

@media only screen and (max-width: 992px) {
  .header-container {
    height: 70px;
  }
}

//Custom Button
.custom-button {
  padding: 10px 35px;
  border-radius: 30px;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    height: 20px;
  }

  &.border-btn {
    border: 2px solid #f6c946;
    color: #fff;
    background: transparent none repeat scroll 0 0;

    &:hover {
      color: #fec60d;
      border-color: #ffffff;
    }
  }

  &.border-light-btn {
    border: 2px solid #5c5c5c;
    color: #fff;
    background: transparent none repeat scroll 0 0;

    &:hover {
      color: #fec60d;
      border-color: #ffffff;
    }
  }
}

//Custome Text Button
.custom-text-button {
  padding: 0;
  border: 0 !important;
  border-radius: 0px;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  box-shadow: none;

  .icon {
    height: 20px;
  }

  &.btn-primary {
    color: #fec60d;
  }

  &.btn-white {
    color: #ffffff;
  }

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

//Search Collection Modal
.header-searchmodal-body {
  background-color: #101018;
  border-radius: 20px;
  padding: 0 !important;
}

.search-collection-container {
  padding: 16px 16px 0px 16px;

  .search-bar {
    display: flex;
    align-items: center;
    // border: 1px solid #373746;
    // border-radius: 8px;
    position: relative;

    .search-img {
      height: 20px;
      width: 20px;
      margin: 0px 10px;
      position: absolute;
      left: 0;
    }

    .search-input-header {
      // border: none;
      // outline: none;
      font-size: 16px;
      border-color: #373746;
      line-height: 24px;
      width: 100%;
      border-radius: 8px;
      padding-left: 40px !important;
      padding-right: 20px !important;
    }

    .search-loading {
      margin: 0px 10px;
      position: absolute;
      right: 0;
    }
  }

  .searched-container {
    max-height: 75vh;
    overflow-y: auto;
    padding: 0px 8px;
  }

  .search-collection {
    margin-bottom: 16px;
  }

  .search-title {
    font-weight: 700;
    font-size: 16px;
    // padding: 8px;
    margin-bottom: 8px;
  }

  .search-results {
    // height: calc(100vh - 80px);
    overflow-y: auto;
    background-color: #101018;

    .search-item-container {
      padding: 8px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid #373746;

      &:hover {
        background-color: #14141f;
      }

      .search-info {
        display: flex;
        align-items: center;
        cursor: pointer;

        .search-img {
          height: 30px;
          width: 30px;
          margin-right: 8px;
          border-radius: 50%;
        }

        .token-name {
          margin-bottom: 5px;
        }

        .token-info {
          color: #686868;
          font-size: 12px;
        }
      }

      .search-result-cross {
        height: 16px;
        width: 16px;
      }
    }
  }
}

.trouble {
  background: #f6c946;
  height: 30px;
  width: 100%;
  p {
    text-align: center;
    color: #000000;
    font-size: 14px;
    font-weight: 800;
  }
}
.mobile-app-icons {
  width: 100%;
  text-align: left;
  padding-bottom: 2rem;
  hr {
    border: 0.5px solid #373746;
    margin-bottom: 20px;
  }
  h3 {
    color: #929292;
    font-size: 1.5rem;
    padding: 0px 20px;
  }

  .link-icon {
    margin-top: 10px;
    padding: 0px 20px;
    img {
      width: 105px;
    }
  }
}

// .search-collection-container {
//     height: 100%;

//     .search-bar {
//         height: 100%;
//         display: flex;
//         align-items: center;
//         padding: 0px 16px 0 8px;

//         .arrow-back {
//             color: #5A5A5A;
//         }

//         .search-input-header {
//             border: none;
//             outline: none;
//             font-size: 16px;
//             width: 100%;
//             padding-left: 0 !important;
//         }
//     }

//     .search-results {
//         height: calc(100vh - 80px);
//         overflow-y: auto;
//         background-color: #14141F;

//         .search-item-container {
//             padding: 12px 16px;
//             font-size: 16px;
//             border-bottom: 1px solid #373746;

//             .search-info {
//                 display: flex;
//                 align-items: center;

//                 .search-img {
//                     border-radius: 8px;
//                     height: 30px;
//                     width: 30px;
//                     margin-right: 5px;
//                 }

//                 .token-name {
//                     margin-bottom: 5px;
//                 }

//                 .token-info {
//                     color: #686868;
//                     font-size: 12px;
//                 }
//             }

//         }
//     }
// }
.conference {
  bottom: 2rem;
  right: 5rem;
  z-index: 99999999999;
  width: 250px;
  background: #000000;
  border-radius: 10px;
  box-shadow: rgb(117 117 117 / 63%) 0px 5px 15px;
  .main_img {
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  h5 {
    font-size: 14px;
    margin: 0;
  }
  p {
    font-size: 10px;
    font-weight: bolder;
    color: #8a8aa0;
  }
  a {
    background: #fec60d;
    border: 1px solid #fec60d;
    border-radius: 8px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    color: #000000;
    font-weight: bolder;
    &:hover {
      color: #000000;
    }
  }
  .live {
    top: 10px;
    left: 8px;
    right: 8px;
    display: flex;
    justify-content: space-between;
    ._span {
      background: #000000;
      padding: 5px 10px;
      border-radius: 15px;
      font-weight: bolder;
      img {
        vertical-align: bottom;
      }
    }
    .span_ {
      background: #000000;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
      }
    }
  }
}

.menu-dropdown {
  display: none;
  position: absolute;
  width: 200px;
  top: 20px;
  z-index: unset;
  background-color: #242836;
  border-radius: 15px;
  margin: 10px 0px;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  li {
    color: #ffffff;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(88, 88, 88, 0.35);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-of-type {
      border-bottom: none;
    }
    &:hover {
      color: #fec60d;
    }
  }
  .active {
    color: #fec60d !important;
    a {
      color: #fec60d !important;
    }
    @media only screen and (max-width: 992px) {
      .menu-icon-yellow {
        display: block !important;
      }
      .menu-icon {
        display: none !important;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    position: static;
    display: block;
    width: 100%;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.menu-link {
  &:hover {
    .menu-dropdown {
      display: block;
    }
    .arrow-icon {
      rotate: 180deg;
    }
  }
  @media only screen and (max-width: 992px) {
    .arrow-icon {
      display: none;
    }
  }
}
