.form-label {
    color: #F5F5F7;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 8px;
    // font-size: 14px;

    .helper-label {
        color: #727272;
    }
}

.custom-invalid-error {
    margin-top: 0.5rem;
    margin-left: 0.75rem;
    color: #EB5757;
    font-size: 12px;
}


.custom-input-control {
    line-height: 1.5 !important;
    font-size: 16px !important;
    padding: 10px 12px !important;
    border-radius: 12px !important;
    border: 1.5px solid #555 !important;
    background-color: transparent !important;
    outline: 0 !important;
    width: 100% !important;
    color: #8a8aa0 !important;
    // &:focus {
    //     background-color: #ffffff;
    // }

    &.in-valid {
        border-color: #EB5757 !important;

        &:focus {
            border-color: #EB5757 !important;
        }
    }
}


.checkbox-control {
    display: block;
    position: relative;
    padding-left: 30px;
    border-radius: 6px;

    &.checbox-control-sm {
        padding-left: 26px;
        .checkbox-label{
            line-height: 20px;
        }
        .checkmark {
            height: 20px;
            width: 20px;
            border-radius: 4px;
        }

    }

    input:checked {
        ~.checkbox-label {

            .checkmark {
                background-color: #F9D423;
                border: 1.5px solid #3C3C3C !important;

                .check-icon {
                    display: block;
                }
            }
        }
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox-label {
        line-height: 24px;
        color: #666666;
        cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: #1D1D1F;
        border: 1.5px solid #555;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .check-icon {
            color: #3C3C3C;
            display: none;
        }
    }
}