.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
  .purple-border {
    border: 5px solid #725bff;
  }
  .green-border {
    border: 5px solid #419400;
  }
  .red-border {
    border: 5px solid #f00;
  }
  .default-border {
    border: 5px solid #f9d423;
  }
  .mint-border {
    border: 5px solid #2a85ff;
  }
  .purple {
    background-color: #725bff;
  }
  .green {
    background-color: #419400;
  }
  .red {
    background-color: #f00;
  }
  .mint {
    background-color: #2a85ff;
  }
  .default {
    background-color: #f9d423;
  }
}

.timeline::after {
  content: '';
  position: absolute;
  width: 5px;
  background: #3a3a3a;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2.5px;
}

.container-timeline {
  padding: 30px 60px;
  position: relative;
  background: inherit;
  width: 50%;
}

.container-timeline.left {
  left: 0;
}

.container-timeline.right {
  left: 50%;
}

.container-timeline .icon {
  position: absolute;
  display: inline-block;
  width: 30px;
  height: 30px;
  top: 45px;
  right: -15px;
  padding: 9px 0;
  border-radius: 50px;

  text-align: center;
  font-size: 30px;
  z-index: 1;
  background: #ffffff;
}

.container-timeline.right .icon {
  left: -15px;
}

.container-timeline .date {
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 30px;
  min-width: 60px;
  padding: 6px 8px;
  position: absolute;
  top: 45px;
  width: 105px;
  z-index: -1;
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.container-timeline.left .date {
  right: -105px;
  border-radius: 0 30px 30px 0;
}

.container-timeline.right .date {
  left: -105px;
  border-radius: 30px 0 0 30px;
}

.container-timeline .content {
  cursor: pointer;
  background: transparent;
  position: relative;
  border: 5px solid #eeeeee;
  border-radius: 15px;
  border: 2.292px solid #3a3a3a;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  img {
    width: 70px;
    border-radius: 5px;
  }
  .badge {
    font-size: 1.1rem;
  }
  .price {
    p {
      font-size: 12px;
      font-weight: bolder;
      color: #8a8aa0;
    }
    h4 {
      font-size: 14px;
    }
  }
  .info {
    p {
      font-size: 12px;
      font-weight: bolder;
      color: #8a8aa0;
    }
    h5 {
      font-size: 14px;
    }
    .badge {
      border-radius: 6px;
      border: 1.5px solid #3a3a3a;
      margin: 1px 4px;
    }
  }
}

.container-timeline.right .content {
  border-radius: 24.069px;
  border: 2.292px solid #3a3a3a;
}

.container-timeline .content h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #e94b3c;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 20px;
  }

  .container-timeline {
    padding: 10px 60px;

    width: 100%;
    padding-left: 50px;
    padding-right: 0px;
  }

  .container-timeline.right {
    left: 0%;
  }

  .container-timeline.left .icon,
  .container-timeline.right .icon {
    width: 30px;
    height: 30px;
    top: 37.5px;
    font-size: 18px;
    left: 5px;
  }

  .container-timeline.left::before,
  .container-timeline.right::before {
    left: 110px;
    border-color: transparent transparent transparent #e94b3c;
  }

  .container-timeline.left .date,
  .container-timeline.right .date {
    right: auto;
    left: 15px;
    border-radius: 30px 0 0 30px;
    display: none;
  }
}
