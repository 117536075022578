.feature-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  .card-container {
    width: calc(100% / 5);
    padding: 0 8px;
  }

  @media only screen and (min-width: 1690px) {
    .card-container {
      width: calc(100% / 6);
    }
  }

  @media only screen and (max-width: 1200px) {
    .card-container {
      width: calc(100% / 4);
    }
  }

  @media only screen and (max-width: 768px) {
    .card-container {
      width: calc(100% / 3);
    }
  }

  @media only screen and (max-width: 576px) {
    .card-container {
      width: calc(100% / 2);
    }
  }
}

.tokencard-container {
  background: #1b1b1b;
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  .ensimage-container {
    border-radius: 8px;
    margin-bottom: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.custom-img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
      padding: 12px;
    }

    .ens-image {
      border-radius: 8px;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  .token-img {
    img {
      border-radius: 8px;
      margin-bottom: 12px;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  .lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
    height: auto !important;
  }

  .token-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .title {
      font-size: 16px;
      margin-right: 4px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;

      @media only screen and (max-width: 768px) {
        font-size: 13px;
      }
    }

    //13px

    .verify-icon {
      height: 14px;
      width: 14px;
    }
  }

  .title-rank-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-size: 16px;
      margin-right: 4px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
    }

    .rank-container {
      border: 2px solid #272b30;
      background-color: #000;
      color: #f9d423;
      padding: 6px 8px;
      border-radius: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
      white-space: nowrap;
    }
  }

  .rarity-value-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #272b30;
    background-color: #000;
    padding: 10px;
    font-weight: bold;
    border-radius: 20px;



    .total-value {
      color: #727272;
    }
  }

  .rank-icon {
    height: 18px;
    width: 18px;
    margin-right: 4px;
  }

  .token-creator-container {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 12px;

    .creator-img {
      height: 20px;
      width: 20px;
    }

    .by-text {
      color: #686868;
      margin: 0px 4px;
    }
  }

  .price-container,
  .traded-container {
    width: 100%;
    border: 1px solid #3d3d3d;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 4px;
    font-size: 12px;
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;

    .price-label {
      font-size: 12px;
      border-bottom: 1px solid #3d3d3d;
      margin-bottom: 6px;
      padding-bottom: 6px;
      color: #6c6c6c;
    }

    .price-value {
      font-size: 14px;
      font-weight: 700;

      span {
        color: #6c6c6c;
      }
    }

    @media only screen and (max-width: 768px) {
      .price-label {
        font-size: 10px;
      }

      .price-value {
        font-size: 12px;
      }
    }
  }

  .view-btn {
    padding: 0;
    width: 100%;
    background: #252525;
    border: 1px solid #5c5c5c;
    border-radius: 8px;
    padding: 10px 0px;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.labelinfo-cardcontainer {
  row-gap: 15px;

  .labelinfo-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #262626;
    background-color: #141414;
  }

  .label-info {
    display: block;
    font-size: 14px;
    color: #8a8aa0;
  }

  .value-info {
    display: block;
    font-size: 22px;
    line-height: 1.5;
    font-weight: bold;
    color: #ffffff;
  }
}

.loadingcard-container {
  background: #1b1b1b;
  border: 1px solid #3d3d3d;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  padding: 10px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  .loading-img {
    margin-bottom: 12px;
    aspect-ratio: 1/1;
    max-width: 100%;
    width: 100%;
  }

  .loading-title {
    margin-bottom: 10px;
  }

  .loading-value {
    display: flex;
    justify-content: space-between;

    div {
      width: 48%;
    }
  }


}

@media only screen and (max-width: 768px) {
  .labelinfo-cardcontainer {
    row-gap: 8px;
  }
}


.airdropcard-container {
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #272B30;
  background: rgba(47, 53, 69, 0.66);
  overflow: hidden;

  .airdropimage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    background: rgba(47, 53, 69, 0.66);
  }

  .project-container {
    background: rgba(47, 53, 69, 0.66);
    width: 100%;

    @media only screen and (max-width: 576px) {
      aspect-ratio: 2/1;
    }
  }

  .projectimage {
    max-width: 100%;
    width: 100%;
    height: 180px;
    object-fit: cover;
    @media only screen and (max-width: 576px) {
      height: auto;
    }
  }

  .airdrop-image {
    height: 100px;
    width: 100px;
  }

  &:hover {
    transform: translateY(-10px);
  }

  .body-container {
    padding: 12px 12px;
  }

  .dropname {
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 4px;
  }


  .dropdescription {
    font-size: 14px;
    font-family: inherit;
    line-height: 1.375;
    color: #8A8AA0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    // >p {
    //   font-size: 14px;
    //   font-family: inherit;
    //   line-height: 1.375;
    //   color: #8A8AA0;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    // }
  }

  .sharebtn {
    border: 0 !important;
    border-radius: 6px !important;
    background: rgba(86, 86, 86, 0.42);
    height: 28px !important;
    width: 28px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-shrink: 0;
    overflow: hidden;

    img {
      height: 18px;
      width: 18px;
      max-width: none;
    }
  }

  .footer-container {
    margin: 0 12px;
    padding: 12px 0;
    border-top: 1px solid #4C5160;

    overflow: hidden;

  }

  .marquee-content {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;

    &.marquee-play {
      animation: marquee 6s linear 0s infinite;
    }

  }


  .footeraction-btn {
    font-size: 14px;
    border: 0 !important;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 6px;
    border-radius: 6px;
    background: rgba(86, 86, 86, 0.42);
    font-weight: 600;
    flex-grow: 1;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .icon-container {
      padding: 0px 4px 0px 0px;
      border-right: 2px solid rgba(255, 255, 255, 0.26);
      flex-shrink: 0;

      img {
        height: 14px;
        width: 14px;
      }
    }

    .btntext {
      padding: 0px 0 0 4px;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }

  .footerchain-btn {
    font-size: 14px;
    border: 0 !important;
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 6px;
    border-radius: 6px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #272B30 !important;
    background: #1A1D1F;

    .icon-container {
      padding: 0px 4px 0px 0px;
      flex-shrink: 0;

      img {
        height: 18px;
        width: 18px;
      }
    }

    .btntext {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }
  }
}

.airdroploadingcard-container {
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #272B30;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background: rgba(47, 53, 69, 0.66);

  &:hover {
    transform: translateY(-10px);
  }

  .loading-img {
    height: 180px;
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 6px;
  }

  .loading-title {
    margin-bottom: 12px;
  }

  .loading-description {
    padding-bottom: 12px;
    border-bottom: 1px solid #4C5160;
    margin-bottom: 12px;
  }

}

.airdropchain-container {
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #272B30;
  background: rgba(47, 53, 69, 0.66);
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
  }


  .chainimage-container {
    background: rgba(47, 53, 69, 0.66);
    height: 180px;
    width: 100%;
  }

  .chainimage {
    max-width: 100%;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  .chainname {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0px;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}