.iteminfo-container {
   
    font-size: 16px;

    .headerimg-container {
        height: 200px;
        // background: url("https://i.seadn.io/gcs/files/8debfc3c44fe3bfbe64b3d6d6ff7c44e.jpg?auto=format&dpr=1&w=3840");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #393939;
    }

    .section-detail {
        .title-container {
            border-top: 2px solid #272B30;
            border-bottom: 2px solid #272B30;
            padding: 14px 12px 12px 12px;
        }

        .body-container {
            padding: 18px 12px;
        }
    }

    .raritycard-container {
        width: 95%;
    }

    .border-lg-right {
        border-right: 2px solid #262626;
    }

    @media only screen and (max-width: 992px) {
        .border-lg-right {
            border-right: 0;
        }
    }

    .rarity-card {
        border: 2px solid #262626;
        background-color: #141414;
        border-radius: 12px;

        .card-title {
            border-bottom: 1px solid #272B30;
            padding: 12px 12px;
            text-align: center;
            text-transform: capitalize;
        }

        .card-body {
            padding: 8px 16px 14px;
            text-align: center;
        }

        .btn-rarity {
            background: #000;
            text-transform: capitalize;
            border-radius: 24px;
            text-align: center;
            padding: 10px 10px;
            max-width: 90%;
            width: 90%;
            border: 2px solid #262626;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }


    }
}