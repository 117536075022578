.walletinfo-container {
  .wallet-color-container {
    background-color: #f9d423;
    height: 200px;
    position: relative;
  }

  .changecolorbtn-container {
    position: absolute;
    margin-left: auto;
    right: 40px;
    bottom: 10px;
  }

  .bucket-icon {
    vertical-align: middle;
    margin-right: 2px;
  }

  .changecolorbtn-wrapper {
    border-radius: 30px;
    padding: 6px;
    background: #292929;
    position: relative;
    z-index: 1;

    .colorpicker {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
    }
  }

  .changecolor-btn {
    outline: 0;
    border: 2px solid #ffffff;
    color: #ffffff !important;
    padding: 6px 12px;
    background-color: transparent !important;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    .changecolorbtn-wrapper {
      padding: 3px;
    }

    .bucket-icon {
      height: 16px;
      width: 16px;
    }

    .changecolorbtn-container {
      right: 12px;
      bottom: 4px;
    }

    .changecolor-btn {
      padding: 4px 6px;
      font-size: 12px;
    }
  }

  .display-blur {
    cursor: pointer !important;
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }

  //Wallet Header

  .label-info {
    display: block;
    font-size: 14px;
    color: #8a8aa0;
  }

  .value-info {
    display: block;
    font-size: 22px;
    line-height: 1.5;
    font-weight: bold;
    color: #ffffff;
  }

  .walletbalance-summary-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    padding: 10px 0 20px 0;
    border-bottom: 2px solid #262626;
  }

  .walletbalance-card {
    border-right: 2px solid #262626;
    padding-right: 30px;
    margin-right: 30px;

    &:last-child {
      border-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .walletbalance-summary-container {
      // padding-top: 0;
    }

    .walletbalance-card {
      width: 50%;
      border-right: 0px;
      padding-right: 0px;
      margin-right: 0px;
      // margin-right: 0;
      // padding: 0;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // padding-top: 10px;
      // padding-bottom: 10px;

      // &:nth-child(even) {
      //     border-right: 2px solid #262626;
      // }

      // &:nth-child(odd) {
      //     border-right: 2px solid #262626;
      //     border-left: 2px solid #262626;
      // }
    }

    // .label-info,
    // .value-info {
    //     text-align: center;
    // }
  }

  .blanaceinfo-cardcontainer {
    row-gap: 15px;
  }

  .balanceinfo-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #262626;
    background-color: #141414;
    .label-info {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .blanaceinfo-cardcontainer {
      row-gap: 8px;
    }
  }

  .alertchat-container {
    padding: 20px 0 20px 0;
    border-bottom: 2px solid #262626;

    .email-wrapper {
      border-right: 2px solid #262626;
    }

    .email-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #262626;
      background-color: #141414;
      border-radius: 12px;
      padding: 12px 16px;
      margin-right: 24px;
    }

    .chatgpt-container {
      display: flex;
      align-items: center;
      border: 2px solid #262626;
      background-color: #141414;
      border-radius: 12px;
      padding: 12px 16px;
      margin-left: 24px;
      cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
      .email-container {
        margin-right: 0;
      }

      .chatgpt-container {
        margin-left: 0;
      }
    }
  }

  .tgl + .tgl-btn {
    width: 120px;
    height: 3rem;
  }

  .tgl-skewed + .tgl-btn:after,
  .tgl-skewed + .tgl-btn:before {
    line-height: 3rem;
  }

  .tabbody-container {
    padding: 16px 10px;
    margin-bottom: 30px;
  }

  .transaction-icon {
    height: 16px;
    width: 16px;
    max-width: none !important;
    vertical-align: middle;
  }

  @media only screen and (max-width: 768px) {
    .tabbody-container {
      padding: 8px 0px;
    }
  }
}

.walletoverview-container {
  .recenttransaction-container {
    height: 100%;
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #262626;
    background-color: #141414;
  }

  .chart-container {
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #262626;
    background-color: #141414;

    &.blur-chart {
      cursor: pointer !important;
      filter: blur(5px);
      -webkit-filter: blur(5px);
    }
    .chart-filter {
      display: flex;
      span {
        cursor: pointer;
        border: 1.5px solid #ebebeb;
        font-weight: 800;
        padding: 8px;
        margin-right: 5px;
        border-radius: 5px;
      }
    }
  }

  .ens-container {
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #262626;
    background-color: #141414;
  }

  .collectionnft-container {
    padding: 16px;
    border-radius: 12px;
    border: 2px solid #262626;
    background-color: #141414;
  }

  .innercard-container {
    background-color: #1b1b1b;
    padding: 16px;
    border-radius: 12px;
  }

  .total-nftimage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 12px;

    .total-nft-image {
      max-width: 100%;
      object-fit: contain;
      border-radius: 6px;
    }
  }
}

.asset-details {
  display: flex;
  align-items: center;

  .avatar-img {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .name-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2;
    width: 100%;
    display: inline-block;
    overflow: hidden;
  }

  .subtitle {
    color: #555555;
    font-size: 14px;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    overflow: hidden;
  }
}

.avatar-group {
  padding-left: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .avatar-group-item {
    margin-left: -12px;
    border: 5px solid #1b1b1b;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
      position: relative;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      z-index: 1;
    }

    .number-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      background-color: #747070;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .avatar-sm {
    height: 40px;
    width: 40px;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }
}

.walletcollections-container {
  font-size: 1rem;

  .headeraction-container {
    display: flex;
    align-items: center;

    .searchwallet-container {
      margin-right: 12px;
    }
  }
  .toggle-ui {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 52px;
    border: 2px solid #262626;
    padding: 2px;
    width: 100px;
    svg {
      width: 50%;
      font-size: 28px;
      padding: 5px 5px;
    }
    .toggle-active {
      border-radius: 10px;
      background: #f9d423;
      color: #000000;
    }
  }

  @media only screen and (max-width: 768px) {
    .headeraction-container {
      flex-direction: column;
      row-gap: 14px;
      align-items: end;

      .searchwallet-container {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}

.tabbody-headercontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .tabbody-headercontainer {
    flex-direction: column;
    row-gap: 12px;
  }
}

.custom-row {
  margin-left: -8px !important;
  margin-right: -8px !important;

  .custom-column {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media only screen and (max-width: 768px) {
    .custom-column {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-row {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
}

.transaction-label {
  padding: 4px 6px;
  color: #ffffff;
  font-size: 14px;
  background-color: #f9d423;
  border-radius: 4px;
  font-weight: 500;
  white-space: nowrap;

  &.transferin {
    background: #00c77f;
  }

  &.transferout {
    background-color: #ff6b62;
  }

  &.mint {
    background-color: #2a85ff;
  }

  &.swap {
    background-color: #fbc400;
  }

  &.sale {
    background-color: #ef3900;
  }

  &.salesold {
    background-color: #ef3900;
  }

  &.salebought {
    background-color: #006400;
  }

  &.burn {
    background-color: #ff7246;
  }
}

.row-gap-xs {
  margin-left: -4px !important;
  margin-right: -4px !important;

  .column-gap-xs {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.row-gap-md {
  margin-left: -8px !important;
  margin-right: -8px !important;

  .column-gap-md {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media only screen and (max-width: 768px) {
    .column-gap-md {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .row-gap-md {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
}

.performance-items-modal {
  min-height: calc(100% - 60px);
  width: 75vw !important;
  max-width: 75vw !important;
}

@media only screen and (max-width: 768px) {
  .performance-items-modal {
    width: 90vw !important;
    max-width: 90vw !important;
  }
}

.performance-backdrop {
  background: rgba(15, 15, 15, 0.83) !important;
  backdrop-filter: blur(32px) !important;
}

.performance-items-modalcontainer {
  border-radius: 8px !important;
  border: 2px solid #3c3c3c !important;
  background: #111 !important;
  box-shadow: none;
  min-height: 80vh;

  .modal-header {
    padding: 12px 16px;
    background: #111;
    border-bottom: 1px solid #262626;

    .headerclose-icon {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
  .scroll-model {
    height: 85vh;

    overflow: auto;
  }

  .modal-body {
    padding: 8px 16px 16px 16px;
    font-size: 16px;
  }
}

//Chat Container Css
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 60px;

  // overflow: hidden;
  width: 35%;
  z-index: 100;
  border-radius: 12px;
  overflow: hidden;
  background-color: #373737;

  .font-14 {
    font-size: 14px;
  }

  .font-12 {
    font-size: 12px;
  }

  .text-grey {
    color: #9f9f9f;
  }

  .main-container {
    background: #373737;
    border-radius: 12px;
  }

  .chatgpt-header-container {
    display: flex;
    justify-content: space-between;
    background: #4b4b4b;
    padding: 12px 12px;

    .chapgpt-userinfo {
      display: flex;
      align-items: center;
      flex-grow: 1;

      .chatgpt-icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
    }

    .chatgpt-header-action {
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    .header-icon {
      height: 12px;
      width: 12px;
      // transform: rotate(0);
      transition: transform 0.4s ease-in-out;

      &.toggle-up {
        transform: rotate(-180deg);
      }
    }

    .close-icon {
      padding: 6px;
      border-radius: 4px;
      background-color: #535252;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .chtagpt-body-container {
    height: auto;
    transition: height 0.8s ease-out;

    .chathistory-container {
      font-size: 14px;
      max-height: 60vh;
      min-height: 30vh;
      padding: 12px;
      overflow-y: auto;
      position: relative;

      .loading-transactions {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #8a8aa0;
      }

      .message-icon {
        height: 20px;
        width: 20px;
      }

      .common-message-section {
        max-width: 80%;
        padding: 8px 12px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }

      .leftchat-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 12px;

        .message-section {
          @extend .common-message-section;
          margin-right: 4px;
          background-color: #10a37f;
          border-bottom-left-radius: 12px;
          word-wrap: break-word;
        }
      }

      .rightchat-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 12px;

        .message-section {
          @extend .common-message-section;
          margin-left: 4px;
          background-color: #555555;
          border-bottom-right-radius: 12px;
          word-wrap: break-word;
        }
      }
    }

    .chatgpt-input-container {
      outline: 0;
      padding: 8px 48px 8px 12px;
      font-size: 14px;
      background-color: #494848;
      border-radius: 12px;
      border-width: 0;
      padding: 12px;

      &::placeholder {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .send-message-container {
      position: relative;
      margin-right: 12px;
      margin-left: 12px;
      margin-bottom: 12px;
    }

    .chat-disabled-form {
      opacity: 0.6;
    }

    .send-btn-container {
      position: absolute;
      right: 6px;
      top: 0px;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .send-message-btn {
      padding: 0;
      height: 34px;
      width: 34px;
      border-radius: 8px;
      background-color: #f9d423;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .chat-container {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .chat-container {
    font-size: 35px;
    right: 15px;
    left: 15px;
    width: calc(100% - 30px);
  }
}

.token-detail-chatgpt-icon {
  width: 48px;
  // margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .token-detail-chatgpt-icon {
    margin-top: 0;
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #10a37f;
  color: #10a37f;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #10a37f;
  color: #10a37f;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
