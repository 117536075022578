.background-dark {
    background-color: #252525 !important;
}

.bg-charletsongreen {
    background-color: #252934 !important;
}

.bg-hybridtask{
    background-color: #121212 !important;
}