.search-ico {
  font-size: 2rem;
  margin-left: 5px;
  vertical-align: inherit;
}

.portfolio-search svg {
  position: absolute;
  right: 1rem;
  top: 10px;
}

.portfolio-search {
  width: fit-content;
}
td.d-flex.justify-content-start.align-items-center.cursor-pointer.onhover:hover
  h5 {
  color: #e9b618 !important;
}

@media only screen and (max-width: 767px) {
  .portfolio-search {
    width: 100%;
  }
}
