.unsubscribe-container {
    font-size: 16px;
    border-radius: 12px;
    border: 3px solid #272B30;
    background: #121314;
    color: #FFFFFF;
    text-align: center;
    overflow: hidden;

    .bg-header {
        background-color: #272B30;
        height: 70px;
        position: relative;
    }

    .walletdetails-container {
        position: absolute;
        top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 1;
    }

    .body-container {
        padding: 120px 16px 32px 16px;
        position: relative;
    }

    .walletdetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .walletaddress {
        color: #8A8AA0;
        font-size: 14px;
    }

    .wallet-img {
        width: 100px;
        height: 100px;
        margin-bottom: 6px;
        border-radius: 12px;
    }

    h2 {
        font-size: 26px;
        line-height: 1.2;
    }
}