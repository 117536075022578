.collectioninfo-container {
   
    font-size: 16px;

    .headerimg-container {
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #393939;
    }

    .collection-text {
        color: #686868;
        text-align: unset;
    }

    .tabbody-container {
        padding: 16px 10px;
        margin-bottom: 30px;
    }

    @media only screen and (max-width: 768px) {
        .tabbody-container {
            padding: 8px 0px;
        }
    }
    .chart-filter {
        display: flex;
        span {
          cursor: pointer;
          border: 1.5px solid #ebebeb;
          font-weight: 800;
          padding: 8px;
          margin-right: 5px;
          border-radius: 5px;
        }
      }

}

.traits-container {
    .traits-row {
        row-gap: 15px;
    }

    .trait-items {}

    .accordian-container {
        border-radius: 12px;
        border: 1px solid #272B30;
        background: #121314;
    }

    .trait-header {
        display: flex;
        justify-content: space-between;
        padding: 14px 16px;
    }

    .trait-item {
        border-bottom: 1px solid #272B30;
        padding: 16px;
        display: flex;
        justify-content: space-between;

        &:first-child {
            border-top: 2px dashed #272B30;
        }

        &:last-child {
            border-bottom: 0px;
        }

        .item-label {
            color: #9A9A9A;
            text-transform: capitalize;
        }
    }
}

.audit-container {
    .details-container {
        row-gap: 15px;
    }

    .audit-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        border: 2px solid #262626;
        background-color: #141414;
        padding: 16px;
        border-radius: 12px;

        .audit-title {
            flex-grow: 1;
            text-overflow: ellipsis;
        }

        .audit-status {
            display: flex;
            align-items: center;
        }
    }
}

.opensea-container,
.lockrare-container {
    .chart-container {
        padding: 16px;
        border-radius: 12px;
        border: 2px solid #262626;
        background-color: #141414;
    }

    .poweredby-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8A8A8A;
        font-weight: bold;

        .logo-icon {
            width: 100px;
            vertical-align: middle;
        }
    }
}