.airdrops-container {
  .airdropbanner-container {
    // height: 60vh;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0.35) 100%
      ),
      url('../../../../assets/images/airdrops/airdropbanner.webp') lightgray -8px -128px /
        100.833% 140.209% no-repeat;
    display: flex;
    padding: 0 40px;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      padding: 0 16px;
    }

    .bannercontent {
      display: flex;
      flex-grow: 1;
      // height: calc(100% - 80px);
      width: 80%;
      margin: auto;
      padding: 60px 0px;

      @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 24px 0px;
      }

      .livebtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: rgba(123, 123, 123, 0.42) !important;
        border: 0;
        padding: 8px 16px;
        margin-bottom: 20px !important;

        @media (max-width: 600px) {
          display: none;
        }

        &::before {
          content: '';
          height: 12px;
          width: 12px;
          border-radius: 7px;
          background-color: rgba(4, 190, 0, 1);
          margin-right: 8px;
        }
      }

      .nolivebtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: rgba(123, 123, 123, 0.42) !important;
        border: 0;
        padding: 8px 16px;
        margin-bottom: 20px !important;

        @media (max-width: 600px) {
          display: none;
        }

        &::before {
          content: '';
          height: 12px;
          width: 12px;
          border-radius: 7px;
          background-color: #fd4438;
          margin-right: 8px;
        }
      }

      .imagecontainer {
        background: rgba(47, 53, 69, 0.66);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 120px 80px;
        flex-shrink: 0;

        @media (max-width: 600px) {
          padding: 60px 40px;
        }
      }

      .airdropimage {
        height: 120px;
        width: 120px;
        border-radius: 30px;
      }

      .contentwrapper {
        padding: 24px 24px;

        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 12px;
        }
      }

      .bannertitle {
        font-size: 36px;
      }

      .descritpion {
        font-weight: 600;
        color: #8a8aa0;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;

        @media (max-width: 600px) {
          text-align: center;
        }
      }

      .actionwrapper {
        display: flex;
        gap: 12px;

        @media (max-width: 600px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }

      .airdropaction-btn {
        font-size: 14px;
        border: 0 !important;
        display: flex;
        align-items: center;
        padding: 8px 12px 8px 10px;
        border-radius: 6px;
        background: rgba(86, 86, 86, 0.42);
        font-weight: 600;
        flex-shrink: 0;
        white-space: nowrap;

        .icon-container {
          margin-right: 8px;
          padding-right: 6px;
          border-right: 2px solid rgba(255, 255, 255, 0.26);
          flex-shrink: 0;

          img {
            height: 14px;
            width: 14px;
          }
        }

        .btntext {
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
        }
      }
    }
  }

  .swipercontainer {
    padding: 16px 0px;
  }

  .sectionaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section-title {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  .airdroptypes-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    width: 100%;

    .card-container {
      width: calc(100% / 5);
      padding: 0 8px;
    }

    // @media only screen and (min-width: 1690px) {
    //     .card-container {
    //         width: calc(100% / 6);
    //     }
    // }

    @media only screen and (max-width: 1200px) {
      .card-container {
        width: calc(100% / 4);
      }
    }

    @media only screen and (max-width: 768px) {
      .card-container {
        width: calc(100% / 3);
      }
    }

    @media only screen and (max-width: 576px) {
      .card-container {
        width: calc(100% / 2);
      }
    }
  }

  .chains-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    width: 100%;

    .card-container {
      width: calc(100% / 5);
      padding: 0 8px;
    }

    @media only screen and (min-width: 1690px) {
      .card-container {
        width: calc(100% / 6);
      }
    }

    @media only screen and (max-width: 1200px) {
      .card-container {
        width: calc(100% / 4);
      }
    }

    @media only screen and (max-width: 768px) {
      .card-container {
        width: calc(100% / 3);
      }
    }

    @media only screen and (max-width: 576px) {
      .card-container {
        width: calc(100% / 2);
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    height: 40px;
    width: 40px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: rgba(109, 109, 109, 0.91);
    // border: 1px solid #000;
    // background: linear-gradient(#000000,#666666);
    color: #ffffff;

    &.swiper-button-disabled {
      opacity: 0 !important;
      cursor: auto;
      pointer-events: none;
    }

    &.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    .swiper-navigation-disabled & {
      display: none !important;
    }

    &:after {
      font-family: swiper-icons;
      font-size: 14px;
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    &:after {
      content: 'prev';
    }

    left: 0px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    &:after {
      content: 'next';
    }

    right: 0px;
    left: auto;
  }

  .swiper-button-lock {
    display: none;
  }
}

.freeairdrops-container {
  .pagetitle {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .headercontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }

  .headeractioncontainer {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .searchairdrop-container {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 576px) {
      flex-grow: 1;
    }

    .searching-btn-container {
      position: absolute;
      background-color: #1a1d1f;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      margin: 0px 8px 0 12px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 0 !important;
      outline: 0 !important;
      color: #6f767e;
    }

    .searchinput-icon {
      height: 20px;
      width: 20px;
      margin: 0px 12px;
      color: #6f767e;
    }

    .search-input {
      font-size: 16px;
      border: 0;
      color: #6f767e;
      line-height: 1.5;
      width: 100%;
      min-width: 300px;
      border-radius: 12px;
      padding-left: 20px !important;
      padding-right: 52px !important;
      border: 2px solid rgba(255, 255, 255, 0.08);
      background: rgba(51, 51, 51, 0.42);
      padding-top: 10px;
      padding-bottom: 10px;

      @media (max-width: 576px) {
        min-width: 100%;
      }

      &::placeholder {
        color: #6f767e;
        font-size: 16px;
      }
    }
  }

  .showfilterbutton {
    font-size: 18px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.08);
    background: rgba(51, 51, 51, 0.42);
    padding: 8px 16px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      display: none !important;
    }
  }

  .filtercontainer-column {
    @media (max-width: 768px) {
      display: none;
    }

    &.show {
      position: fixed;
      inset: 0;
      height: 100vh;
      z-index: 20;
      background: #000;
      padding: 0 !important;
      display: flex !important;
      flex-direction: column;
    }
  }

  .filters-container {
    border-radius: 10px;
    background: rgba(47, 53, 69, 0.66);
    position: sticky;
    top: 100px;

    @media only screen and (max-width: 768px) {
      position: relative;
      top: 0;
      border-radius: 0;
      background: transparent;
      flex-grow: 1;
    }

    .filterheader {
      padding: 16px 16px;
      border-bottom: 2px solid #4c5160;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .filterbody {
      padding: 20px 16px;
    }

    .chainscontainer {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .closefilterbtn-container {
    padding: 12px 16px;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;

    @media only screen and (min-width: 768px) {
      display: none;
    }

    .closefilterbtn {
      padding: 10px 16px;
      width: 100%;
    }
  }

  .airdropscard-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;

    .card-container {
      width: calc(100% / 4);
      padding: 0 8px;
    }

    @media only screen and (min-width: 1690px) {
      .card-container {
        width: calc(100% / 5);
      }
    }

    @media only screen and (max-width: 1200px) {
      .card-container {
        width: calc(100% / 3);
      }
    }

    @media only screen and (min-width: 576px) and (max-width: 992px) {
      .card-container {
        width: calc(100% / 2);
      }
    }

    @media only screen and (max-width: 576px) {
      .card-container {
        width: calc(100%);
      }
    }

    // @media only screen and (max-width: 480px) {
    //     .card-container {
    //         width: 287px;
    //     }
    // }
  }
}

.jointelegram-container {
  border-radius: 8px;
  border: 2px solid #272b30;
  background: rgba(47, 53, 69, 0.66);
  padding: 24px;

  .iconcontainer {
    height: 70px;
    width: 70px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.15);
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 60px;
      width: 60px;
    }

    img.subscribe {
      height: 50px;
      width: 50px;
    }
  }

  .title {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 6px;
  }

  .description {
    font-size: 14px;
    line-height: 1.375;
    color: #8a8aa0;
    margin-bottom: 16px;
  }

  .actioncontainer {
    padding-top: 16px;
    border-top: 2px solid rgba(88, 88, 88, 0.61);
  }

  .emailinput {
    margin-top: 22px;
    border-radius: 8px;
    border: 1px solid #333;
    background: #1b1b1b;
    padding-top: 10px !important;
    margin-bottom: 20px;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .jointelegram-container {
    padding: 16px;
  }
}

.airdropoverview-container {
  .airdropcolor-container {
    background-color: #ffc7c7;
    height: 100px;
    position: relative;
  }

  ol,
  ul {
    padding: 1.5rem !important;
    line-height: 1.2;
  }

  li {
    list-style: unset !important;
  }

  //Wallet Header
  .airdropdetails-wrapper {
    position: relative;
    height: 60px;
    margin-bottom: 20px;

    @media only screen and (max-width: 576px) {
      margin-bottom: 8px;
    }

    .airdropdetails-container {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      .airdrop-img {
        width: 100px;
        height: 100px;
        margin-right: 8px;
        border-radius: 20px;
        object-fit: cover;
      }
    }

    .airdropbasic-container {
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      @media only screen and (max-width: 576px) {
        flex-direction: column;
      }
    }

    .airdopbasicdetails {
      display: flex;

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }

    .airdrop-name {
      font-size: 22px;
      line-height: 1.2;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .header-actions {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .likebtn {
      display: flex;
      align-items: center;
      border-radius: 16px;
      border: 2px solid #272b30;
      background: #1a1d1f;
      font-size: 16px;
      padding: 10px 16px !important;
      font-weight: 600;
      white-space: nowrap;

      @media only screen and (max-width: 576px) {
        padding: 8px 12px !important;
        border-radius: 12px;
        display: none;
      }

      &:hover {
        svg {
          fill: #f9d423;
        }
      }
    }

    .sharebutton-actions {
      border: 2px solid #272b30;
      background-color: #1a1d1f;
      border-radius: 16px;
      padding: 8px;
      font-size: 20px;
      display: flex;
      flex-shrink: 0;
      position: relative;

      @media only screen and (max-width: 576px) {
        padding: 6px;
        font-size: 15px;
        border-radius: 12px;
      }

      .share-icon {
        padding: 4px 8px;
        color: #b1b1b1;
        display: flex;

        &.twitter-icon {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        &.website-icon {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        &.border-right {
          border-right: 2.5px solid #272b30;
        }

        &:hover {
          color: #f9d423;

          &.twitter-icon {
            svg {
              path {
                color: #f9d423;
                fill: #f9d423;
              }
            }
          }

          &.website-icon {
            svg {
              path {
                color: #f9d423;
                fill: #f9d423;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        .share-icon {
          padding: 6px 8px;

          &.twitter-icon {
            svg {
              width: 15px;
              height: 15px;
            }
          }

          &.website-icon {
            svg {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .sharebtn {
      display: flex;
      align-items: center;
      border-radius: 12px;
      border: 2px solid #272b30;
      background: #1a1d1f;
      font-size: 16px;
      padding: 10px 16px !important;
      font-weight: 600;
      white-space: nowrap;

      @media only screen and (max-width: 576px) {
        padding: 8px 12px !important;
        border-radius: 8px;
      }

      img {
        height: 18px;
        width: 18px;
        max-width: none;
      }
    }
  }

  .mobileairdropdetails {
    display: none;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 2px solid #4c5160;

    @media only screen and (max-width: 576px) {
      display: block;
    }

    .airdrop-name {
      font-size: 22px;
      line-height: 1.2;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .likebtn {
      display: flex;
      align-items: center;
      border: 2px solid #272b30;
      background: #1a1d1f;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      padding: 8px 12px !important;
      border-radius: 12px;

      &:hover {
        svg {
          fill: #f9d423;
        }
      }
    }
  }

  .infocards-container {
    row-gap: 12px;

    .infocards {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 12px;
      justify-content: space-between;
      align-items: center;
      border: 2px solid #272b30;
      background-color: #252934;

      @media only screen and (max-width: 768px) {
      }
    }
  }

  .strategycard {
    padding: 16px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 576px) {
      padding: 12px;
    }

    .iconcontainer {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      width: 38px;
      background: rgba(249, 212, 35, 0.21);
      margin-right: 12px;

      img {
        height: 24px;
        width: 24px;
      }
    }

    .descriptioncontainer {
      border-left: 2px solid #4c5160;
      padding: 0 12px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      word-break: break-word;
      color: #ffffff;
      p {
        line-height: unset !important;
        color: #ffffff !important;
      }

      a {
        color: #e9b618;
      }
      @media only screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.375;

        @media only screen and (max-width: 576px) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .fundamentals-section {
    font-size: 16px !important;
    color: #8a8aa0 !important;
    font-weight: 500;

    > ul {
      list-style: disc !important;
      padding-left: 16px;

      li {
        list-style: inherit !important;
        padding: 4px 0;
        line-height: 1.375 !important;
      }
    }
  }

  .dynamic-section {
    > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.375 !important;
      color: #8a8aa0;
    }

    > ul {
      font-size: 16px !important;
      color: #8a8aa0 !important;
      font-weight: 500;
      list-style: disc !important;
      padding-left: 16px;

      li {
        list-style: inherit !important;
        padding: 4px 0;
        line-height: 1.375 !important;
      }
    }
  }

  .basiccard-container {
    background-color: #252934;
    position: sticky;
    top: 100px;

    .cardheader {
      padding: 16px 16px;
      border-bottom: 2px solid #45464b;
    }

    .cardbody {
      padding: 16px;

      .listsections {
        font-size: 16px !important;

        font-weight: 500;

        > ul {
          list-style: disc !important;
          padding-left: 16px;

          li {
            list-style: inherit !important;
            padding: 4px 0;
            line-height: 1.375 !important;
            color: #8a8aa0;
            cursor: pointer;

            &.active {
              color: #ffffff;
              font-weight: 600;
            }

            &:hover {
              color: #ffffff;
            }
          }
        }
      }

      .supportingtokens {
        font-size: 14px !important;
        color: #8a8aa0 !important;
        font-weight: 500;
        padding: 0px 30px;

        @media only screen and (max-width: 768px) {
          padding: 0px 16px;
        }

        > ul {
          list-style: disc !important;
          padding-left: 16px;

          li {
            list-style: inherit !important;
            padding: 2px 0;
            line-height: 1.375 !important;
          }
        }
      }
    }
  }
}

.custom-row-sm {
  margin-left: -6px !important;
  margin-right: -6px !important;

  .custom-column {
    padding-left: 6px;
    padding-right: 6px;
  }

  // @media only screen and (max-width: 768px) {
  //     .custom-column {
  //         padding-left: 4px !important;
  //         padding-right: 4px !important;
  //     }
  // }
}

@media only screen and (max-width: 768px) {
  .custom-row {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
   font-size: 2rem;
  }
}

.hybridtask-modal-container {
  padding-left: 0;
}

.hybridtask-modal {
  min-height: calc(100% - 60px);

  .modal-content {
    border-radius: 20px !important;
    border: 2px solid #3c3c3c;
    overflow: hidden;
  }
}

.hybridtaskmodal-header {
  display: flex;
  font-size: 18px !important;
  font-weight: 600;
  background: #252934;
  border-bottom: 1px solid #3c3c3c !important;

  .iconcontainer {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    background: rgba(249, 212, 35, 0.21);
    margin-right: 12px;

    img {
      height: 24px;
      width: 24px;
    }
  }
}

.hybridtaskmodal-body {
  font-size: 16px;
  background: #252934;
  padding: 16px;

  .strategycard {
    padding: 12px 12px;
    display: flex;
    align-items: center;

    .iconcontainer {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      width: 38px;
      background: rgba(249, 212, 35, 0.21);
      margin-right: 12px;

      img {
        height: 24px;
        width: 24px;
      }
    }

    .descriptioncontainer {
      border-left: 2px solid #4c5160;
      padding: 0 12px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > p {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.375;

        @media only screen and (max-width: 576px) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
