.py-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.px-14 {
    padding-right: 14px;
    padding-left: 14px;
}

.px-16 {
    padding-right: 16px;
    padding-left: 16px;
}


.px-20 {
    padding-right: 20px;
    padding-left: 20px;
}


.mt-16 {
    margin-top: 16px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.pb-16 {
    padding-bottom: 16px;
}

.max-w-350px {
    max-width: 350px !important;
}

.min-h-350px {
    min-height: 350px !important;
}


.min-w-250px {
    min-width: 250px;
}

.max-w-250px {
    max-width: 250px;
}

.max-w-none {
    max-width: none !important;
}

.w-250px {
    width: 250px;
}

.min-w-300px {
    min-width: 300px;
}

.max-w-300px {
    max-width: 300px;
}

.w-300px {
    width: 300px;
}

.min-w-220px {
    min-width: 220px;
}

.w-220px {
    width: 220px;
}

.min-w-200px {
    min-width: 200px;
}

.w-200px {
    width: 200px;
}

.max-w-180px {
    max-width: 180px;
}

.min-w-180px {
    min-width: 180px;
}

.w-180px {
    width: 180px;
}

.max-w-150px {
    max-width: 150px;
}

.min-w-150px {
    min-width: 150px;
}

.min-h-150px {
    min-height: 150px;
}


.w-150px {
    width: 150px;
}

.min-w-120px {
    min-width: 120px;
}

.w-120px {
    width: 120px;
}

.min-w-100px {
    min-width: 100px;
}

.w-100px {
    width: 100px;
}



.min-w-48px {
    min-width: 48px;
}

.max-w-48px {
    max-width: 48px;
}

.w-48px {
    width: 48px;
}

.px-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.min-h-400px {
    min-height: 400px !important;
}


.w-per-10 {
    width: 10%;
}

.max-w-per-10 {
    max-width: 10% !important;
}

.min-w-per-10 {
    min-width: 10%;
}

.w-20px {
    width: 20px;
}

.max-w-initial {
    max-width: initial !important;
}


@media only screen and (max-width: 575px) {
    .w-xs-100 {
        width: 100%;
    }
}