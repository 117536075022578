.home-custom-container {
  width: 90%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
}
.section-1 {
  padding-top: 5rem;
  // height: 100vh;
  width: 100%;
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding-top: 4rem;
  }
  #tracker-form {
    background-image: url('../../../images/home-new/Gradient_29.webp');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }
  h3 {
    font-size: 35px;
    font-weight: 900;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #ffffff;
    span {
      color: #f9d423;
    }
  }
  .button-1 {
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.37);
    width: 150px;
    height: 40px;
    padding: 8px;
    color: rgba(255, 255, 255, 0.69);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.689px; /* 73.327% */
    letter-spacing: -0.241px;
  }
}

.section-2 {
  padding: 6rem 0px;
  .head-title {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.4;
    @media only screen and (max-width: 992px) {
      font-size: 25px;
    }
  }
  @media only screen and (max-width: 992px) {
    padding: 4rem 0px;
  }
  .card-list {
    margin-top: 5rem;
    @media only screen and (max-width: 992px) {
      margin-top: 3rem;
    }
  }
  .section-2-card {
    border-radius: 20px;
    border: 2px solid #272b30;
    background: rgba(47, 53, 69, 0.66);
    .icon {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
      }
    }
    .section-2-card-body {
      padding-bottom: 2.5rem;
      border-bottom: 2px solid rgba(88, 88, 88, 0.35);
      h4 {
        font-size: 25px;
        font-weight: 800;
      }
      p {
        color: #8a8aa0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
      }
    }
    .section-2-card-footer {
      a {
        color: #000000;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.156px;
        text-align: center;
      }
    }
  }
}

.section-4 {
  .first {
    padding: 7rem 0rem;
    h4 {
      color: #ffffff;
      font-size: 4rem;
      font-style: normal;
      font-weight: 800;
      line-height: 1.2;
    }
    p {
      color: #8a8aa0;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5;
    }
    .group {
      h5 {
        font-size: 2.1rem;
        margin-bottom: 10px;
      }
      span {
        color: #cecece;
        font-size: 12px;
      }
    }
    ._border_left {
      border-left: 2px solid #373a42;
    }
    @media only screen and (max-width: 768px) {
      .group {
        h5 {
          font-size: 1.4rem;
          margin-bottom: 10px;
        }
      }
      h4 {
        font-size: 3rem;
      }
      padding: 4rem 0rem;
    }
  }
  .SectionAirdrop {
    background-image: url('../../../images/home-new/airdrop.webp') !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 768px) {
      height: 300px;
      background-position: top;
    }
  }
  .second {
    background-image: url('../../../images/home-new/home-section4.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media only screen and (max-width: 768px) {
      height: 300px;
      background-position: top;
    }
  }

  background: #242836;
}

.section-5 {
  width: 100%;
  padding: 6rem 0;
  .section-2-card {
    border-radius: 20px;
    border: 2px solid #272b30;
    background: rgba(47, 53, 69, 0.66);
    .icon {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
      }
    }
    .section-2-card-body {
      h4 {
        font-size: 25px;
        font-weight: 800;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      p {
        color: #8a8aa0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .section-2-card-footer {
      a {
        color: #000000;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.156px;
        text-align: center;
      }
    }
  }
  .sub-heading {
    .first {
      img {
        width: 25px;
      }
      span {
        color: #8a8aa0;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.331px;
      }
    }
    h4 {
      color: #ffffff;
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: 1.5; /* 71.918px */
    }
    p {
      color: #8a8aa0;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    a {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.156px;
      text-align: center;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
.section-6 {
  width: 100%;
  padding: 6rem 0;
  @media only screen and (max-width: 768px) {
    padding: 1rem 0;
  }
  .list {
    width: 100%;
    padding: 1.5rem 0;
    border-bottom: 1px solid #373a42;
    &:last-child {
      border-bottom: unset;
    }
    div {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background: #242836;
      margin-right: 1rem;
    }
    img {
      width: 25px;
    }
    p {
      color: #8a8aa0;
      font-size: 17px;
      font-weight: 600;
    }
  }
  .first {
    img {
      width: 25px;
    }
    span {
      color: #8a8aa0;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.331px;
    }
  }
  h4 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5; /* 71.918px */
  }
  p {
    color: #8a8aa0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  a {
    color: #000000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.156px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.section-7 {
  h4 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5; /* 71.918px */
  }
}

.section-8 {
  width: 100%;
  padding: 2rem 0 0 0;
  margin-top: 6rem;
  background: #242836;
  h4 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5; /* 71.918px */
  }
  p {
    color: #8a8aa0;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
  }
  .links {
    img {
      width: 150px;
    }
  }
}

.section-9 {
  width: 100%;
  padding: 6rem 0px;
  .first {
    img {
      width: 25px;
    }
    span {
      color: #8a8aa0;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.331px;
    }
  }
  h4 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5; /* 71.918px */
  }
  p {
    color: #8a8aa0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    &:nth-child(1) {
      font-weight: 800;
    }
  }

  a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.156px;
    text-align: center;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .list {
    border-radius: 12px;
    background: #242836;
    padding: 15px;
    .image {
      padding: 8px;
      border-radius: 10px;
      background: #464954;
      img {
        width: 40px;
      }
      @media only screen and (max-width: 768px) {
        padding: 5px;
        img {
          width: 30px;
        }
      }
    }

    h5 {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 1.4;
      @media only screen and (max-width: 768px) {
        font-size: 11px;
        font-weight: 700;
        line-height: 1.4;
      }
    }
  }
}

.section-3 {
  width: 100%;
  padding-bottom: 4rem;
  h4 {
    color: #ffffff;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5; /* 71.918px */
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .toggle {
    background: #242836;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 2px solid #262626;
    padding: 2px;
    width: 220px;
    text-align: center;
    span {
      font-size: 12px;
      padding: 10px 5px;
      width: 100%;
      font-weight: 700;
    }
    .toggle-active {
      border-radius: 20px;
      background: #f9d423;
      color: #000000;
    }
  }
  .table-list {
    img {
      width: 30px;
      border-radius: 10px;
      margin-right: 5px;
    }
    .lazy-load-image-background.blur {
      height: 30px !important;
    }
    .mint-details {
      display: flex;
      align-items: center;
      a {
        font-size: 14px;
      }
      .verfied {
        margin-left: 5px;
        width: 12px;
      }
    }
  }
}
