//Common classes
@media only screen and (max-width: 576px) {
  .homesection-title {
    font-size: 24px;
    margin-bottom: 1.5rem !important;
  }
}

.home-banner-container {
  color: white;
  margin-top: 80px;
  // height: calc(50vh - 80px);
  padding: 20px 0px;
  position: relative;

  .title {
    font-size: 55px;
  }

  @media only screen and (max-width: 768px) {
    .title {
      font-size: 35px;
    }
  }

  .banner-action-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  @media only screen and (min-width: 1400px) {
    .banner-action-container {
      padding: 2rem 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .banner-action-container {
      padding: 0;
    }
  }

  .subtitle {
    color: #636363 !important;
    text-align: center;
  }

  .ownership-img {
    width: 70%;
  }

  .background-img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }

  .background-img-mobile {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .ownership-img {
      width: 85%;
    }

    .background-img {
      display: none;
    }

    .background-img-mobile {
      display: block;
    }
  }

  .search-container {
    background: #21202a;
    border: 1px solid #848484;
    position: relative;
    display: flex;
    border-radius: 12px;
    padding: 2px;
    // width: 65%;
    width: 100%;

    .search-input {
      border: 0px;
      border-radius: 0;
      padding: 10px 15px;
      font-size: 16px;
      line-height: 28px;

      &::placeholder {
        font-size: 16px;
      }
    }

    @media only screen and (max-width: 768px) {
      .search-input {
        border-radius: 12px;
        border: 1px solid #393939;
        margin-right: 5px;
        font-size: 14px;
        line-height: 18px;

        &::placeholder {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .searching-btn-container {
      position: absolute;
      background-color: #1a1d1f;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      margin: 0px 8px 0 12px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 0 !important;
      outline: 0 !important;
      color: #6f767e;
    }

    .search-btn {
      display: flex;
      align-items: center;
      background: #f9d423;
      border-radius: 0px 10px 10px 0px;
      color: #252525;
      font-weight: bold;
      padding: 15px 25px;
    }

    .search-btn-mobile {
      display: none;
      align-items: center;
      background: #f9d423;
      border-radius: 12px;
      padding: 10px 15px;

      .search-icon-container {
        height: 20px;
        width: 20px;

        img {
          vertical-align: top;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .search-btn {
        display: none;
      }

      .search-btn-mobile {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .search-container {
      padding: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    .search-container {
      // width: 95%;
    }
  }

  .home-previous-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    padding: 0px 5px;
  }

  .recentsearch-btn {
    font-size: 14px !important;
    padding: 6px 16px !important;
  }

  .paste-container {
    font-size: 12px;
    color: #5a88ff;
    cursor: pointer;
    white-space: nowrap;

    .copy-icon {
      height: 15px;
      width: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    .home-previous-container {
      flex-wrap: wrap;
      row-gap: 6px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-banner-container {
    min-height: 75vh;
    height: auto;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 992px) {
  .home-banner-container {
    margin-top: 70px;
  }
}

.portfolio-section {
  padding: 50px 0 0 0;

  .feature-container {
    padding: 0;
    margin: 0;
    margin-bottom: 3rem;
    color: #d2d2d2;

    .feature-info {
      display: flex;
      align-items: center;
      padding: 10px 0;
      font-size: 18px;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .feature-icon {
      width: 24px;
      margin-right: 8px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .portfolio-section {
    padding-top: 0px;
  }
}

.minting-section {
  padding: 40px 0 0 0;
  overflow: hidden;

  .mint-details {
    display: flex;
    align-items: center;

    .mint-img {
      img {
        height: 40px;
        width: 40px;
        border-radius: 8px;
        margin-right: 8px;
      }
    }

    .lazy-load-image-background.blur.lazy-load-image-loaded {
      width: 100%;
      height: auto !important;
    }

    .lazy-load-image-background.blur {
      height: 40px !important;
      width: 40px;
    }

    .mint-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      overflow: hidden;
    }

    .created-date {
      color: #555555;
    }
  }

  .progress-unique-minters {
    max-width: 60%;
    background-color: #444444;
    border: 1.5px solid #686868;
    border-radius: 8px;
    min-width: 150px;

    .progress-bar {
      height: 0.75rem !important;
      border-radius: 8px !important;
      background-color: #f9d423 !important;
    }
  }

  .mintnow-btn {
    background-color: #2a85ff !important;
    color: #ffffff;
    transition: none !important;
    border-color: #99c5ff !important;
    border-radius: 12px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    width: max-content;

    &:hover {
      font-weight: 700 !important;
    }
  }
}

.digitalmembership-container {
  overflow: hidden;

  .membership-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-items: center;
    padding: 60px 40px;
    background: url('../../../../assets/images/home/digitalmembership-desktop.webp');
    background-size: 100% 100%;
    object-fit: contain;
    width: 75%;
  }

  .buisness-logo {
    width: 120px;
  }

  .membership-actions {
    display: flex;
    flex-wrap: wrap;
    margin: 2px 0px 15px 0;
  }

  .membership-title {
    line-height: 1.2;
  }

  @media only screen and (max-width: 992px) {
    .membership-title {
      br {
        display: none;
      }
    }

    .membership-container {
      width: 100%;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .membership-container {
      width: 85%;
    }
  }

  @media only screen and (max-width: 768px) {
    .membership-container {
      background: url('../../../../assets/images/home/digitalmembership-mobile.webp');
      background-size: 100% 100%;
      padding: 24px 12px;
    }

    .membership-title {
      font-size: 22px;
    }

    .membership-actions {
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.request-modal-container {
  padding-left: 0;
}

.request-modal {
  min-height: calc(100% - 60px);
}

.membership-request-modal {
  font-size: 16px;
  border-radius: 20px !important;
  background: #161616 !important;
  color: #f5f5f7;
  padding: 0;

  .mb-16 {
    margin-bottom: 16px;
  }

  .request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid #555;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .requestmodal-body {
    flex-grow: 1;

    padding: 30px 24px;
  }

  .startrequest-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 24px;
  }

  .startrequest-footer {
    border-top: 1px solid #555;
    padding: 20px 24px;
    text-align: center;

    .text-hatefrom {
      color: #797979;
    }
  }

  .service-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    border: 1px solid #555;
    background: #1d1d1f;
    border-radius: 12px;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
      border-color: #f9d423;

      .service-option-radio {}
    }

    &.service-selected {
      border-color: #f9d423;

      .service-option-radio {
        background-color: #f9d423;

        .check-icon {
          display: block;
        }
      }
    }

    .service-option-radio {
      height: 20px;
      width: 20px;
      padding: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      border: 1px solid #555;
      font-size: 14px;
      font-weight: bold;

      .check-icon {
        display: none;
      }
    }
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .line-height-title {
    line-height: 1.25;
  }

  .thankyoutab-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .startrequest-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .buisnesslogo-container {
      border: 4px solid #f9d423;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      height: 120px;
      width: 120px;
      overflow: hidden;
      align-items: center;
      margin-bottom: 20px;
    }

    .buisness-logo {
      width: 80px;
    }

    .info {
      font-size: 16px;
      line-height: 1.25;
    }
  }

  .stepper-container {
    display: flex;

    .step-options {
      width: 20px;
      height: 4px;
      background-color: rgba(136, 136, 136, 0.4);
      border-radius: 2px;
      margin: 0px 3px;

      &.active {
        background-color: #006ddb;
      }
    }
  }
}

.section-top-padding {
  padding-top: 75px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .section-top-padding {
    padding-top: 40px;
  }
}