.text-light-dark {
    color: #5C5C5C;
}

.text-dark-white {
    color: #D2D2D2;
}

.text-light-grey {
    color: #555555;
}

.text-muted {
    color: #646464;
}

.text-neutral {
    color: #8A8AA0;
}

.text-success {
    color: #15FFAB;
}

.text-success-dark {
    color: #15FF73 !important;
}

.text-danger {
    color: #FD4438;
}

.text-custom-primary {
    color: #F9D423 !important;
}

.text-link {
    color: #1F6DDE !important;
}

.text-green{
    color: #49E300;
}