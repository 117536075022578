.table-container {
    overflow-x: auto;
    max-width: 100%;
}


.loading-component {
    min-height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #8a8aa0;
    // font-weight: bold;
}

.custom-table {
    box-shadow: none;
    width: 100%;
    border-collapse: collapse;
    color: #ffffff;
    border: 0 !important;
    table-layout: auto;
    margin-bottom: 0;

    td {
        padding: 12px 10px !important;
        // border-bottom: 1px solid #272B30 !important;
        border: 0;
        font-size: 16px !important;
        vertical-align: middle;
    }


    th {
        padding: 12px 10px !important;
        font-weight: 600;
        border: 0 !important;
        border-bottom: 1px solid #272B30 !important;
        font-size: 16px !important;
        color: #646464;
        vertical-align: middle;
        user-select: none;

        &:first-child {
            padding-left: 18px !important;
        }
    }

    td {
        &:first-child {
            padding-left: 18px !important;
        }
    }


    .table-head {
        text-transform: uppercase;
        // background-color: #FAF9FB;
    }

    .verticle-middle {
        vertical-align: middle;
    }

    tbody {

        tr {
            &:hover {
                background-color: #1e1f25;
            }
        }

    }

    .nodata-component {
        padding: 10px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sortingcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.25rem;

        svg {
            color: #646464;
            width: 14px;
            font-weight: bold;

            &.active {
                color: #ffffff;
            }
        }

        .arrow-up {
            margin-right: -5px;
        }
    }


}