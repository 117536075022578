.nodata-container {
    width: 100%;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    .empty-img {
        width: 200px;
        margin-bottom: 24px;
    }

    .nodata-title {
        margin-bottom: 1rem;
        text-align: center;
    }

    .nodata-message {
        color: #8A8AA0;
        text-align: center;
        line-height: 1.4;

    }

    @media only screen and (max-width: 768px) {
        .empty-img {
            width: 160px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .nodata-container {
        padding: 20px;
    }
}