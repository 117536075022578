@import 'ntfs.css';
@import 'shortcodes.css';
@import 'responsive.css';
/* @import 'animate.css'; */
@import 'font-awesome.css';
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'bootstrap.css';
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 62.5%;
  overflow-y: scroll;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background: var(--primary-color);
  height: 100%;
  line-height: 1;
  /* overflow: hidden; */
  padding-right: 0 !important;
  width: 100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
blockquote,
q {
  quotes: none;
}
a img {
  border: 0;
}
select {
  max-width: 100%;
}
body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-color2);
  font-family: Urbanist, sans-serif;
  text-rendering: optimizeLegibility;
}
img {
  -ms-interpolation-mode: bicubic;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
p {
  color: var(--primary-color4);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
b,
cite,
strong {
  font-weight: 700;
}
blockquote,
cite,
dfn,
em,
i {
  font-style: italic;
}
abbr,
acronym {
  border-bottom: 1px dotted #e0e0e0;
  cursor: help;
}
ins,
mark {
  text-decoration: none;
}
sub,
sup {
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
small,
sub,
sup {
  font-size: 75%;
}
big {
  font-size: 125%;
}
address {
  font-style: italic;
  margin: 0 0 20px;
}
code,
kbd,
pre,
samp,
tt,
var {

  border-radius: 0;
  height: auto;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
  margin: 20px 0;
  overflow-x: auto;
  padding: 4px 12px;
}
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
hr {
  border: 1px dashed #ccc;
  margin-bottom: 20px;
}
.vh-100 {
  height: 100vh;
}
ol,
ul {
  padding: 0;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li > ol,
li > ul {
  margin-bottom: 0;
}
li {
  list-style: none;
}
ol li,
ul li {
  padding: 0.1em 0;
}
dd,
dl {
  margin: 0 0 20px;
}
dt {
  font-weight: 700;
}
.disable,
del {
  filter: alpha(opacity=50);
  opacity: 0.5;
  text-decoration: line-through;
}
table,
td,
th {
  border: 1px solid #343444;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 30px;
  table-layout: fixed;
  width: 100%;
}
caption,
td,
th {
  font-weight: 400;
  text-align: left;
}
th {
  font-weight: 700;
}
td,
th {
  border-width: 0 1px 1px 0;
}
td,
th {
  padding: 8px 12px;
}
embed,
object,
video {
  margin-bottom: 20px;
  max-width: 100%;
  vertical-align: middle;
}
p > embed,
p > iframe,
p > object,
p > video {
  margin-bottom: 0;
}
button,
input {
  line-height: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  vertical-align: baseline;
}
input,
select,
textarea {
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0)
  );
  font-size: 14px;
  max-width: 100%;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
input[type='checkbox'] {
  display: inline;
}
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  border: 0;
  cursor: pointer;
  line-height: 1;
}
input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  height: 18px;
  margin-right: 11px;
  padding: 0;
  vertical-align: sub;
  width: 18px;
}
input[type='search'] {
  -webkit-appearance: textfield;
}
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1000px #f7f7f7;
}
input[type='search'] {
  outline: 0;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
.wpcf7-form input,
.wpcf7-form select,
.wpcf7-form textarea {
  margin-bottom: 0;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
}
.select {
  overflow: hidden;
  position: relative;
}
.select:after {
  background: 0 0;
  color: #1f1f2c;
  content: '\f078';
  display: block;
  font-family: Font Awesome\5 Pro;
  font-size: 14px;
  font-weight: 600;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
select option {
  color: #1f1f2c;
  font-size: 15px;
  line-height: 24px;
}
input[type='color'],
input[type='date'],
input[type='datetime-local'],
input[type='datetime'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
textarea {
  background: transparent;
  border: 1px solid rgba(138, 138, 160, 0.3);
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #8a8aa0;
  font-size: 18px;
  line-height: 28px;
  outline: 0;
  padding: 13px 15px;
  width: 100%;
}
input[type='color']:focus,
input[type='date']:focus,
input[type='datetime-local']:focus,
input[type='datetime']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='time']:focus,
input[type='url']:focus,
input[type='week']:focus,
textarea:focus {
  border: 1px solid #8a8aa0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
input[type='color']::placeholder,
input[type='date']::placeholder,
input[type='datetime-local']::placeholder,
input[type='datetime']::placeholder,
input[type='email']::placeholder,
input[type='month']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='tel']::placeholder,
input[type='text']::placeholder,
input[type='time']::placeholder,
input[type='url']::placeholder,
input[type='week']::placeholder,
textarea::placeholder {
  color: #8a8aa0;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  padding: 15px 39px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
button:focus,
button:hover,
input[type='button']:focus,
input[type='button']:hover,
input[type='reset']:focus,
input[type='reset']:hover,
input[type='submit']:focus,
input[type='submit']:hover {
  border: 1px solid var(--primary-color3);
  color: var(--primary-color3);
  outline: 0;
}
::-webkit-input-placeholder {
  color: #8a8aa0;
}
:-moz-placeholder {
  color: #8a8aa0;
}
::-moz-placeholder {
  color: #8a8aa0;
  opacity: 1;
}
.error {
  color: var(--primary-color2);
  font-size: 16px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
:-ms-input-placeholder {
  color: #8a8aa0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.msg-success {
  animation: rainbow 2s ease-in-out infinite;
  background: linear-gradient(90deg, #e250e5, #4b50e6, #e250e5);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 200%;
  color: transparent;
  font-size: 18px;
  font-weight: 700;
  font-weight: 400;
  line-height: 28px;
  transition: color 0.2s ease-in-out;
}
label.error {
  color: red;
}
.live-auctions .swiper-button-next:after,
.live-auctions .swiper-button-prev:after {
  font-family: Font Awesome\5 Pro;
  font-size: 20px;
}
.live-auctions .swiper-button-next:after {
  content: '\f061';
}
.live-auctions .swiper-button-prev:after {
  content: '\f060';
}
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.wrap-inner {
  display: block;
  width: 100%;
}
.center,
.text-center {
  text-align: center;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-32 {
  font-size: 32px !important;
}
.pd-0 {
  padding: 0 !important;
}
.mg-t-29 {
  margin-top: 29px;
}
.mg-bt-50 {
  margin-bottom: 50px;
}
.mg-bt-60 {
  margin-bottom: 60px;
}
.mg-bt-62 {
  margin-bottom: 62px;
}
.mg-bt-10 {
  margin-bottom: 10px;
}
.mg-r-12 {
  margin-right: 12px;
}
.mg-bottom-0 {
  margin-bottom: 0;
}
.mg-t16 {
  margin-top: 16px;
}
.mg-bt-32 {
  margin-bottom: 32px !important;
}
.mg-bt-12 {
  margin-bottom: 12px;
}
.mg-bt-2 {
  margin-bottom: 2px;
}
.mg-bt-21 {
  margin-bottom: 21px;
}
.mg-l-39 {
  margin-left: 39px;
}
.mg-bt-31 {
  margin-bottom: 31px;
}
.mg-bt-43 {
  margin-bottom: 43px;
}
.mg-bt-23 {
  margin-bottom: 23px;
}
.mg-bt-16 {
  margin-bottom: 16px !important;
}
.mg-bt-20 {
  margin-bottom: 20px;
}
.mg-bt-30 {
  margin-bottom: 30px;
}
.mg-bt-13 {
  margin-bottom: 13px;
}
.mg-t-24 {
  margin-top: 24px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-t-22 {
  margin-top: 22px;
}
.mg-t-15 {
  margin-top: 15px;
}
.mg-t-21 {
  margin-top: 21px;
}
.mg-t-37 {
  margin-top: 37px;
}
.mg-t-9 {
  margin-top: -9px;
}
.mg-t-4 {
  margin-top: -4px;
}
.mg-t-2 {
  margin-top: -2px;
}
.mg-t2 {
  margin-top: 2px;
}
.mg-t32 {
  margin-top: 32px;
}
.mg-bt-24 {
  margin-bottom: 24px;
}
.mg-bt-22 {
  margin-bottom: 22px;
}
.mg-bt-40 {
  margin-bottom: 40px;
}
.mg-bt-41 {
  margin-bottom: 41px;
}
.mg-bt-44 {
  margin-bottom: 44px;
}
.pad-r-50 {
  padding-right: 50px;
}
.pad-t-23 {
  padding-top: 23px;
}
.pad-t-24 {
  padding-top: 24px;
}
.pad-t-17 {
  padding-top: 17px;
}
.pad-t-20 {
  padding-top: 20px;
}
.pad-t-4 {
  padding-top: 4px;
}
.pad-b-54 {
  padding-bottom: 54px !important;
}
.pad-b-74 {
  padding-bottom: 74px !important;
}
.mg-t-40 {
  margin-top: 40px;
}
.mg-t-36 {
  margin-top: 36px;
}
.mg-bt-18 {
  margin-bottom: 18px;
}
.pad-l-7 {
  padding-left: 7px;
}
.pad-0-15 {
  padding: 0 15px;
}
.mg-r-3 {
  margin-right: 3px;
}
.mg-r-1 {
  margin-right: 1px;
}
.color-47A432 {
  background-color: #47a432 !important;
}
.color-9835FB {
  background-color: #9835fb !important;
}
.color-DF4949 {
  background-color: #df4949 !important;
}
.no-box-shadown {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.pad-b-60 {
  padding-bottom: 60px !important;
}
.pad-b-20 {
  padding-bottom: 20px !important;
}
.pad-400 {
  padding: 0 400px;
}
.pad-420 {
  padding: 0 420px;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-l-8 {
  margin-left: 10px;
}
.mg-r-8 {
  margin-right: 10px;
}
.mg-t-11 {
  margin-top: 11px !important;
}
.mg-t-12 {
  margin-top: 12px !important;
}
.mg-t-6 {
  margin-top: 6px !important;
}
.pd-t-20 {
  padding-top: 20px;
}
.pd-15 {
  padding: 0 15px;
}
.bg-style {
  background-color: var(--primary-color5) !important;
}
.bg-style2 {
  background-color: #fff !important;
}
.is_dark .bg-style2 {
  background-color: #1f1f2c !important;
}
.bg-style3 {
  background-color: #f8f8f8;
}
.is_dark .bg-style3 {
  background-color: #1f1f2c !important;
}
.home-1 .bg-home,
.home-2 .bg-home,
.home-3 .bg-home-3 {
  background-color: var(--primary-color5) !important;
}
.mg-t-42 {
  margin-top: 42px;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-11 {
  padding-bottom: 11px !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pb-23 {
  padding-bottom: 23px !important;
}
.pb-39 {
  padding-bottom: 39px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pd-40 {
  padding: 40px;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-17 {
  padding-bottom: 17px !important;
}
.pb-18 {
  padding-bottom: 18px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-22 {
  padding-bottom: 22px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.mt-29 {
  margin-top: 29px;
}
.mb-11 {
  margin-bottom: 11px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-21 {
  margin-top: 21px;
}
.pt-24 {
  padding-top: 24px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mg-bt-3 {
  margin-bottom: 3px;
}
.pl-17 {
  padding-left: 17px;
}
.pl-34 {
  padding-left: 34px;
}
.pl-51 {
  padding-left: 51px;
}
.pl-68 {
  padding-left: 68px;
}
.pl-20 {
  padding-left: 20px;
}
.mg-style2 {
  margin-left: -3px;
  margin-right: -3px;
}
#wrapper {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}
.color-popup {
  color: var(--primary-color2);
}
a {
  color: var(--primary-color2);
  letter-spacing: 0.1px;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:focus,
a:hover {
  color: var(--primary-color3);
  outline: 0;
}
.exp,
.hover-ecfect {
  animation: rainbow 2s ease-in-out infinite;
  background: linear-gradient(90deg, #e250e5, #4b50e6, #e250e5);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 200%;
  color: var(--primary-color2);
  font-weight: 700;
  transition: color 0.2s ease-in-out;
}
.exp:hover,
.hover-ecfect:hover {
  color: transparent !important;
}
@keyframes rainbow {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 100%;
  }
  to {
    background-position: 0;
  }
}
.link-style-1 a {
  color: var(--primary-color4);
}
.link-style-1 a,
.link-style-1 a:focus,
.link-style-1 a:hover {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.link-style-1 a:focus,
.link-style-1 a:hover {
  color: var(--primary-color3);
  outline: 0;
}
:root {
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  --primary-color3: #f6c946;
  --primary-color4: #7a798a;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --bg-section: #fff;
  --bg-section2: #f8f8f8;
  --primary-color8: #7a798a;
  --primary-color9: #7a798a;
}
.is_dark {
  --primary-color: #343444;
  --primary-color2: #fff;
  --primary-color4: hsla(0, 0%, 100%, 0.9);
  --primary-color5: #030303;
  --primary-color6: #fff;
  --primary-color7: #343444;
  --bg-section: #14141f;
  --bg-section2: #14141f;
  --primary-color8: #fff;
  --primary-color9: #8a8aa0;
}
.dark .logo_header {
  height: 56px !important;
  width: 135px !important;
}
header {
  padding-right: 0 !important;
}
.header_1 {
  border-bottom: 1px solid hsla(0, 0%, 92%, 0.2);
  height: 80px;
  position: absolute;
  width: 100%;
  z-index: 100;
}
.header_1.header_2.style2 {
  align-items: center;
  border: none;
  display: flex;
  height: 104px;
}
.admin_active {
  display: none;
}
.connect-wal .admin_active {
  display: block;
}
.main-nav {
  left: 26%;
  position: absolute;
  top: 0;
  z-index: 0;
}
.header_1-inner {
  height: 80px;
  position: relative;
}
.header_1 .header_avatar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f9fbfc;
  border: 1px solid #e2eef1;
  border-radius: 2000px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 9;
}
.header_1 .header_avatar .avatar {
  border-radius: 2000px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 40px;
}
.header_1 .header_avatar .price {
  color: #14141f;
  font-size: 16px;
  padding: 0 10px 0 20px;
}
.header_1 .header_avatar .avatar_popup {
  background: #fff;
  border: 1px solid #e2eef1;
  border-radius: 10px;
  -webkit-box-shadow: 0 17px 13px 0 #192c4b05;
  box-shadow: 0 17px 13px 0 #192c4b05;
  font-size: 16px;
  opacity: 0;
  overflow: hidden;
  padding: 30px;
  position: absolute;
  right: 0;
  top: 60px;
  -webkit-transform: translateY(-1rem);
  transform: translateY(-1rem);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  visibility: hidden;
}
.header_1 .header_avatar .avatar_popup.visible {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}
.header_1 .header_avatar .avatar_popup .links {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.header_1 .header_avatar .avatar_popup .links a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #183b56;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header_1 .header_avatar .avatar_popup .links a i {
  font-size: 20px;
  margin-right: 5px;
}
.header_1 .header_avatar .avatar_popup .links a:hover {
  color: #566ffe;
}
.header_1 .header__notifications .js-notifications-icon {
  cursor: pointer;
}
.header_1 .header_avatar .avatar_popup .links a i {
  font-size: 16px;
  margin-right: 10px;
}
.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-bottom: calc(20px * var(--cb-space-y-reverse));
  margin-top: calc(20px * (1 - var(--cb-space-y-reverse)));
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: -15px;
}
.mt-19 {
  margin-top: 19px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-10 {
  margin-left: 10px;
}
.avatar_popup .copy-text a,
.avatar_popup .copy-text span {
  color: #14141f;
}
#site-logo {
  height: 80px;
}
.flat-search-btn {
  align-items: center;
  display: flex;
  justify-content: end;
  padding: 16px 0;
  position: absolute;
  right: 15px;
  top: 0;
}
#site-logo-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-logo img {
  width: 133px;
}
.main-logo h2,
.main-logo img {
  float: left;
}
.logo-dark {
  display: none;
}
.is_dark .logo-dark,
.logo-light {
  display: block;
}
.is_dark .logo-light {
  display: none;
}
.main-nav ul {
  margin: 0;
}
.main-nav ul.menu {
  display: flex;
}
.main-nav ul li {
  list-style: none;
  position: relative;
}
.main-nav > ul > li {
  padding: 26px 25px 26px 13px;
}
.main-nav > ul > li > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
}
.main-nav > .menu > li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: Font Awesome\5 Pro;
  font-weight: 300;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.main-nav .sub-menu {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: -2px 6px 23px #b4b4b44f;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 100%;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  visibility: hidden;
  width: 250px;
  z-index: 9999;
}
.main-nav .sub-menu:before {
  background-color: #fff;
  content: '';
  height: 15px;
  left: 25px;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
  width: 15px;
}
.main-nav .right-sub-menu {
  left: auto;
  right: 0;
}
.main-nav .sub-menu li a {
  color: #14141f;
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
}
.main-nav .sub-menu li:not(:last-child) a {
  border-bottom: 1px solid #e1e1e18e;
}
.main-nav li:hover .sub-menu {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}
.main-nav .sub-menu li a:hover,
.main-nav .sub-menu li.current-item a {
  color: var(--primary-color3);
}
.main-nav > ul > li > a:hover {
  color: #fff;
}
.main-nav > ul > li .sub-menu li {
  position: relative;
}
.main-nav > ul > li .sub-menu li a {
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
}
.main-nav > ul > li .sub-menu li a:hover,
.main-nav > ul > li .sub-menu li.current-item a {
  padding-left: 35px;
}
.main-nav > ul > li .sub-menu li a:hover:after,
.main-nav > ul > li .sub-menu li.current-item a:after {
  width: 12px;
}
.main-nav > ul > li .sub-menu li a:after {
  background: var(--primary-color3);
  content: '';
  height: 2px;
  left: 15px;
  position: absolute;
  top: 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 0;
}
@media only screen and (max-width: 1600px) {
  .main-nav {
    left: 18%;
  }
  .main-nav > ul > li {
    padding: 26px 12px;
  }
}
@media only screen and (max-width: 991px) {
  .main-nav {
    background-color: #fff;
    box-shadow: 0 3px 16px rgba(47, 83, 109, 0.12);
    height: 100vh;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
    width: 40%;
    z-index: 999999;
  }
  .is_dark .main-nav {
    background: #14141f;
  }
  .main-nav ul.menu {
    flex-direction: column;
  }
  .main-nav > ul > li:first-child {
    border: none;
  }
  .main-nav > ul > li {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
    padding: 0;
  }
  .main-nav ul > li > a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    padding: 0 15px;
  }
  .main-nav.active {
    transform: translateX(0);
  }
  .main-nav.active ul {
    flex-direction: column;
  }
  .main-nav.active ul li {
    background-color: #fff;
    border-top: 1px solid rgba(138, 138, 160, 0.3);
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .is_dark .main-nav.active ul li {
    background-color: #14141f;
  }
  .main-nav.active ul > li > a {
    color: #14141f;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    padding: 0 15px;
  }
  .is_dark .main-nav.active ul > li > a {
    color: #fff;
  }
  .main-nav.active ul li:first-child {
    border-top: 0;
  }
  .main-nav.active ul li > ul > li:first-child {
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }
  .main-nav.active ul > li.current-menu-item > a {
    color: var(--primary-color3);
  }
  .is_dark .main-nav.active ul > li.current-menu-item > a {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
  }
  .main-nav.active .current-item > a,
  .main-nav.active ul > li > a:hover {
    color: var(--primary-color3);
  }
  .is_dark .main-nav.active .current-item > a,
  .is_dark .main-nav.active ul > li > a:hover {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
  }
  .main-nav.active .sub-menu .menu-item a {
    margin-left: 15px;
  }
  .main-nav.active .menu-item-has-children .arrow {
    cursor: pointer;
    display: inline-block;
    font-family: FontAwesome;
    font-size: 20px;
    line-height: 48px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 0;
    width: 48px;
  }
  .main-nav.active .menu-item-has-children .arrow:before {
    color: var(--primary-color2);
    content: '\f107';
    font-family: Font Awesome\5 Pro;
  }
  .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
    -webkit-background-clip: text;
    color: var(--primary-color3);
  }
  .main-nav.active .menu-item-has-children .arrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .main-nav.active .menu-item-has-children .arrow.active:before {
    content: '\f107';
  }
  .main-nav.active ul ul li {
    background-color: var(--primary-color);
    border-top: 1px solid rgba(138, 138, 160, 0.3);
  }
  .is_dark .main-nav.active ul ul li {
    background-color: #14141f;
  }
  .main-nav .sub-menu {
    width: 100%;
  }
  .is_dark .main-nav .sub-menu {
    background: #14141f;
  }
  .main-nav .sub-menu:before {
    display: none;
  }
  .main-nav.active .sub-menu {
    box-shadow: none;
    display: none;
    opacity: 1;
    position: relative;
    visibility: visible;
  }
  .main-nav.active .menu-item.active .sub-menu {
    display: block;
  }
  .main-nav.active .sub-menu:before {
    display: none;
  }
  .main-nav.active .sub-menu li:not(:last-child) a {
    border: none;
  }
  .header_1.header_2 #main-nav {
    left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .main-nav {
    width: 65%;
  }
}
#site-header .header-search-icon {
  display: block;
  font-size: 14px;
  line-height: 100px;
}
.opacity_0 {
  opacity: 0;
}
.header-search {
  margin-right: 36px;
  padding: 14px 0;
  position: relative;
}
.header-search .show-search {
  color: #fff;
  font-size: 20px;
}
.top-search.active {
  filter: alpha(opacity=100);
  opacity: 1;
  visibility: visible;
}
.top-search {
  opacity: 0;
  position: absolute;
  right: -16px;
  top: 130%;
  visibility: hidden;
  width: 250px;
}
.search-submit {
  background: none;
  background: transparent;
  border: none;
  border-radius: 0;
  border-radius: 30px;
  color: #14141f;
  font-size: 20px;
  line-height: 20px;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.search-submit:hover {
  border: none;
  color: #fff;
}
.search-form {
  position: relative;
}
.search-form .search-field,
.search-form .search-field:focus {
  background: #f8f8f8;
  border: 1px solid #fff;
  border-radius: 30px;
  color: #14141f;
  line-height: 23px;
  padding: 13px 50px 13px 15px;
  width: 100%;
}
.search-form .search-field::placeholder {
  color: #14141f;
  font-size: 16px;
  line-height: 23px;
}
.icon-fl-search-filled {
  color: #14141f;
}
.mobile-button {
  background-color: transparent;
  cursor: pointer;
  display: none;
  float: right;
  height: 26px;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 26px;
}
.mobile-button span,
.mobile-button:after,
.mobile-button:before {
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mobile-button:after,
.mobile-button:before {
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  top: 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  width: 100%;
}
.mobile-button span {
  height: 3px;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: 200%;
  top: 50%;
  width: 100%;
}
.mobile-button:before {
  -webkit-transform: translate3d(0, -7px, 0);
  -moz-transform: translate3d(0, -7px, 0);
  transform: translate3d(0, -7px, 0);
}
.mobile-button:after {
  -webkit-transform: translate3d(0, 7px, 0);
  -moz-transform: translate3d(0, 7px, 0);
  transform: translate3d(0, 7px, 0);
}
.mobile-button.active span {
  opacity: 0;
}
.mobile-button.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mobile-button.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.tf-button-submit:hover {
  color: #fff;
  opacity: 0.9;
}
.is_dark .tf-button-submit:hover {
  color: #fff;
}
.header_1.is-fixed {
  background: linear-gradient(227.3deg, #8a208c, #181b81 100.84%);
  border: none;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  left: 0;
  opacity: 1;
  position: fixed;
  top: -161px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  z-index: 9999;
}
.is_dark .header_1.is-fixed {
  background: #14141f;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.5);
}
.header_1.header_2.is-fixed .mode_switcher {
  display: inline-flex;
}
.header_1.is-fixed.is-small {
  top: 0;
}
#header_admin.is-fixed {
  background-color: rgba(81, 66, 252, 0.93);
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.3);
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  z-index: 9999;
}
.header_1.is-fixed .search-form .search-field,
.header_1.is-fixed .search-form .search-field:focus {
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(47, 47, 47, 0.3);
}
.header_1.is-fixed .search-form .search-field,
.header_1.is-fixed .search-form .search-field::placeholder,
.header_1.is-fixed .search-form .search-field:focus,
.header_1.is-fixed .search-submit {
  color: #14141f;
}
.themesflat-pagination {
  margin: 32px 30px;
}
.themesflat-pagination ul {
  margin: 0;
  position: relative;
}
.themesflat-pagination ul li {
  display: inline-block;
  margin-right: 7px;
  padding: 0;
}
.themesflat-pagination ul li .page-numbers {
  border: 1px solid var(--primary-color4);
  border-radius: 3px;
  color: #787878;
  display: inline-block;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 29px;
}
.themesflat-pagination ul li .page-numbers.current,
.themesflat-pagination ul li .page-numbers:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.themesflat-pagination ul li .next,
.themesflat-pagination ul li .prev {
  color: #878787;
  font-size: 14px;
  font-weight: 700;
}
.themesflat-pagination ul li .next:hover,
.themesflat-pagination ul li .prev:hover {
  color: var(--primary-color);
}
#footer {
  background: var(--bg-section);
  padding: 80px 0;
}
#footer.style-2 {
  background-color: #fff;
}
.home-3 #footer,
.home-5 #footer {
  background: #fff !important;
}
.home-6 #footer {
  background: var(--bg-section) !important;
}
.is_dark .home-3 #footer,
.is_dark .home-5 #footer {
  background: #0d0d11 !important;
}
.is_dark #footer.style-2 {
  background-color: #14141f;
}
#scroll-top {
  background-color: #5142fc;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  bottom: 23px;
  cursor: pointer;
  display: block;
  height: 50px;
  line-height: 68px;
  position: fixed;
  right: 14px;
  text-align: center;
  width: 50px;
  z-index: 1;
}
.is_dark #scroll-top {
  background: #fff;
}
#scroll-top svg {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
}
.is_dark #scroll-top svg {
  color: #5142fc;
}
#scroll-top:hover {
  transform: translateY(-7%);
}
.widget .title-widget,
.widget p {
  color: var(--primary-color2);
}
.fl-dark .widget .title-widget,
.fl-dark .widget p {
  color: var(--primary-color);
}
#footer .widget p {
  font-size: 14px;
  line-height: 22px;
}
.side-bar .widget p {
  font-size: 15px;
}
#footer .title-widget {
  line-height: 26px;
}
#side-bar .title-widget {
  line-height: 30px;
  margin-bottom: 20px;
}
#side-bar .title-widget.style-2 {
  line-height: 26px;
  margin-bottom: 14px;
}
#side-bar .widget {
  margin-bottom: 40px;
}
#side-bar .widget.mgbt-24 {
  margin-bottom: 24px;
}
.sc-widget.style-1 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
#side-bar.style-2 {
  padding-left: 50px;
}
#side-bar .widget.mgbt-0 {
  margin-bottom: 0;
}
#side-bar.style-3 {
  padding-right: 55px;
}
.sub-title.small {
  font-size: 14px;
  line-height: 22px;
}
.sub-title.style-2 {
  margin-bottom: 40px;
}
.widget-logo .logo-footer a {
  color: var(--primary-color3);
  font-size: 36px;
  font-weight: 700;
}
.widget-logo .logo-footer {
  margin-bottom: 13px;
}
.widget-logo .sub-widget-logo {
  margin-bottom: 26px;
  padding-right: 35px;
}
.widget-social ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.widget-social.style-1 a {
  background-color: #ebebeb;
  color: #7a798a;
}
.is_dark .widget-social.style-1 a {
  background-color: #343444;
  color: #fff;
}
.widget-social.style-1 a:hover {
  background-color: var(--primary-color3);
  color: var(--primary-color);
}
.is_dark .widget-social.style-1 a:hover {
  background-color: var(--primary-color3);
  color: #fff;
}
.widget-social.style-1 ul li {
  margin-right: 12px;
}
.widget-social.style-1 ul li:last-child {
  margin-right: 0;
}
.widget-social.style-1 ul li a {
  align-items: center;
  border-radius: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 21px;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.widget-social.style-1 ul li.style-2 a {
  font-size: 22px;
}
.widget-social.style-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.widget-social.style-2 .title-widget {
  line-height: 26px;
  margin-right: 17px;
}
.widget-social.style-2 ul {
  margin-right: 3px;
}
.widget-social.style-2 ul li {
  margin-left: 12px;
}
.widget-social.style-2 ul li:first-child {
  margin-left: 0;
}
.widget-social.style-2 ul li a {
  color: #7a798a;
  font-size: 16px;
  line-height: 26px;
}
.widget-social.style-2 ul li a:hover {
  color: var(--primary-color3);
}
.is_dark .widget-social.style-2 ul li a:hover {
  color: #fff;
}
.widget-social.style-3 ul li a {
  background-color: #fff;
  color: #14141f;
}
.widget-social.style-3 ul li a:hover {
  background-color: #5142fc;
  color: #fff;
}
.widget-social.style-3 ul li {
  margin-right: 12px;
}
.widget-social.style-3 ul li:last-child {
  margin-right: 0;
}
.widget-social.style-3 ul li a {
  align-items: center;
  border-radius: 8px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 25px;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 40px;
}
.widget-social.style-3 ul li.style-2 a {
  font-size: 22px;
}
.widget-menu {
  padding-top: 18px;
}
.widget-menu.style-1 {
  padding-left: 30px;
}
.widget-menu.style-2 {
  padding-left: 38px;
}
.widget-menu.fl-st-3 {
  padding-left: 33px;
}
.widget-menu .title-widget {
  margin-bottom: 24px;
}
.widget-menu ul li {
  margin-bottom: 14px;
}
.widget-menu ul li:last-child {
  margin-bottom: 0;
}
.widget-menu ul li a {
  font-size: 14px;
  line-height: 22px;
  position: relative;
}
.widget-menu ul li a:after {
  background: var(--primary-color3);
  bottom: 0;
  content: '';
  height: 1px;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  visibility: hidden;
  width: 0;
}
.widget-menu ul li a:after,
.widget-menu ul li a:hover:after {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget-menu ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.widget-subcribe {
  padding-left: 20px;
  padding-top: 17px;
}
.widget-subcribe .title-widget {
  margin-bottom: 25px;
}
.widget-subcribe .sub-widget-subcribe {
  margin-bottom: 23px;
  padding-right: 5px;
}
.widget-subcribe .form-subcribe form {
  position: relative;
}
.widget-subcribe .form-subcribe input {
  background: #f8f8f8;
  border-radius: 10px;
  font-size: 14px;
  padding: 13px 50px 12px 22px;
  width: 100%;
}
.is_dark .widget-subcribe .form-subcribe input {
  background: transparent;
}
.widget-subcribe .form-subcribe input:focus {
  border: 1px solid #5142fc;
}
.widget-subcribe .form-subcribe input {
  border: 1px solid rgba(138, 138, 160, 0.3);
}
.widget-subcribe .form-subcribe input::placeholder {
  font-size: 14px;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
}
.widget-subcribe .form-subcribe button {
  background-color: var(--primary-color3);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  color: var(--primary-color);
  font-size: 21px;
  padding: 15px 18px 14px 19px;
  position: absolute;
  right: 0;
  top: 0;
}
.widget-subcribe .form-subcribe button,
.widget-subcribe .form-subcribe button .icon-fl-send {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget-subcribe .form-subcribe button .icon-fl-send,
.widget-subcribe .form-subcribe button:hover .icon-fl-send {
  color: #fff;
}
.widget-subcribe .form-subcribe button:hover {
  opacity: 0.9;
}
.box-recent-post,
.widget-recent-post ul li.box-recent-post {
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(47, 47, 47, 0.1);
  cursor: default;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 8px 12px 8px 8px;
  width: 100%;
}
.is_dark .widget-recent-post ul li.box-recent-post {
  background: #343444;
}
.widget-recent-post ul li.box-recent-post {
  margin-bottom: 16px;
}
.widget-recent-post ul li:last-child {
  margin-bottom: 0;
}
.box-recent-post .box-feature img {
  border-radius: 6px;
  height: 44px;
  object-fit: cover;
  width: 47px;
}
.box-recent-post .box-content {
  padding-left: 10px;
  width: 86%;
}
.box-recent-post .box-content span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.box-recent-post .box-content .title-recent-post {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
.box-recent-post .box-content .sub-recent-post {
  color: var(--primary-color4);
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
}
.box-recent-post .box-content .day-recent-post {
  color: var(--primary-color9);
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
}
.box-recent-post .box-content .day-recent-post:hover {
  color: var(--primary-color3);
}
.widget-tag.style-1 {
  margin-right: -5px;
}
.widget-tag ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}
.widget-tag.style-1 ul li {
  margin-bottom: 13px;
  margin-right: 12px;
}
.widget-tag a.box-widget-tag {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 40px;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 109, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  line-height: 38px;
  padding: 0 28px;
}
.widget-tag a.box-widget-tag:hover {
  background-color: var(--primary-color3);
  color: #fff;
}
.fl-dark .widget-tag a.box-widget-tag {
  background-color: #343444;
}
.fl-dark .widget-tag a.box-widget-tag:hover {
  background-color: var(--primary-color3);
  color: var(--primary-color);
}
.widget-tag.style-2 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.widget-tag.style-2 .title-widget {
  line-height: 26px;
  margin-right: 15px;
}
.widget-tag.style-2 ul li {
  margin-right: 6px;
}
.widget-tag.style-2 ul li:after {
  content: ',';
}
.widget-tag.style-2 ul li:last-child:after {
  content: '';
}
.widget-tag.style-2 ul li a {
  color: #7a798a;
  font-size: 18px;
  line-height: 28px;
}
.widget-tag.style-2 ul li a:hover {
  color: var(--primary-color3);
}
.is_dark .widget-tag.style-2 ul li a:hover {
  color: #fff;
}
.widget-search form {
  position: relative;
}
.widget-search input {
  background: transparent;
  border: 1px solid rgba(138, 138, 160, 0.3);
  border-radius: 10px;
  font-size: 13px;
  padding: 10px 70px 8px 19px;
  width: 100%;
}
.widget-search input.style-2 {
  font-size: 14px;
  padding: 14px 70px 12px 19px;
}
.widget-search input.style-2::placeholder {
  letter-spacing: 0;
}
.widget-search input:focus {
  background: 343444;
  border: 1px solid #343444;
}
.widget-search input::placeholder {
  font-size: 14px;
  letter-spacing: -0.5px;
}
.widget-search button {
  background-color: var(--primary-color3);
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  color: #fff;
  font-size: 17px;
  padding: 12.5px 22px;
  position: absolute;
  right: 0;
  top: 0;
}
.widget-search button.style-2 {
  padding: 16.5px 22px;
}
.widget-search button i {
  color: #fff;
}
.widget-filter ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.widget-filter.style-2 ul li {
  margin-bottom: 13px;
  margin-right: 12px;
}
.widget-filter a.box-widget-filter i {
  color: var(--primary-color3);
  margin-right: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.is_dark .widget-filter a.box-widget-filter i {
  color: #fff;
}
.widget-filter a.box-widget-filter.active i,
.widget-filter a.box-widget-filter:hover i {
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.widget-filter a.box-widget-filter {
  color: var(--primary-color2);
}
.widget-filter a.box-widget-filter.active,
.widget-filter a.box-widget-filter:hover {
  background-color: var(--primary-color3);
  color: #fff;
}
.fl-dark .widget-filter .btn-checkbox {
  border: 1px solid #343444;
}
.is-dark .widget-category.boder-bt {
  border-bottom: 1px solid #343444;
}
.widget-category form label {
  letter-spacing: -0.5px;
}
.widget-filter a i {
  margin-right: 8px;
}
.widget-filter a.box-widget-filter {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 40px;
  box-shadow: 0 2px 6px 0 rgba(47, 83, 109, 0.1);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  line-height: 38px;
  padding: 0 20px;
}
.fl-dark .widget-filter a.box-widget-filter {
  background-color: #343444;
}
.fl-dark .widget-filter a.box-widget-filter:hover {
  background: var(--primary-color3);
  color: var(--primary-color);
}
.widget-filter .btn-filter {
  color: var(--primary-color3);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
}
.widget-filter .btn-filter.style-2 {
  padding-top: 5px;
}
.widget-filter .btn-filter:hover {
  color: var(--primary-color2);
}
.fl-dark .widget-filter .btn-filter:hover {
  color: var(--primary-color);
}
.widget-filter.style-1 .header-widget-filter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.widget-filter.style-1 form label {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;
}
.widget-filter.style-1 form label.mgbt-none {
  margin-bottom: 0;
}
.widget-filter.style-1 input {
  height: 100%;
  left: 0;
  margin-right: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-filter .btn-checkbox {
  background-color: transparent;
  border: 1px solid #7a798a;
  border-radius: 8px;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}
.widget-filter.style-1 form label input:checked ~ .btn-checkbox {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}
.widget-filter .btn-checkbox:after {
  content: '';
  display: none;
  position: absolute;
}
.widget-filter.style-1 form label input:checked ~ .btn-checkbox:after {
  display: block;
}
.widget-filter .btn-checkbox:after {
  color: #fff;
  content: '\e921';
  font-family: nfts;
  left: 6px;
  top: 2px;
}
.is_dark .widget-category.boder-bt {
  border-color: #343444;
}
.widget-category .title-wg-category {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}
.widget-category .title-wg-category i {
  font-size: 7px;
  font-weight: 600;
  padding-top: 9px;
}
.widget-category .title-widget {
  line-height: 26px;
}
.widget-category form {
  margin-bottom: 23px;
}
.widget-category form label {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -0.7px;
  line-height: 16px;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
}
.widget-category form label.mgbt-none {
  margin-bottom: 0;
}
.widget-category input {
  height: 100%;
  left: 0;
  margin-right: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-category.style3 input {
  opacity: 1;
  position: relative;
}
.widget-category .btn-checkbox {
  background-color: transparent;
  border: 1px solid #7a798a;
  border-radius: 4px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}
.widget-category form label input:checked ~ .btn-checkbox {
  background-color: var(--primary-color3);
  border: 1px solid var(--primary-color3);
}
.widget-category .btn-checkbox:after {
  content: '';
  display: none;
  position: absolute;
}
.widget-category form label input:checked ~ .btn-checkbox:after {
  display: block;
}
.widget-category .btn-checkbox:after {
  color: #fff;
  content: '\e921';
  font-family: nfts;
  font-size: 10px;
  left: 2px;
  top: 0;
}
.profileImage {
  border-radius: 50%;
  height: 150px;
  width: 150px;
}
body {
  background: var(--bg-section);
}
#root {
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}
.walletList svg {
  font-size: 2.5rem;
}
.react-colorful {
  width: 100% !important;
}
