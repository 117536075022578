/* added for popup */
.popup-content {
  margin: auto;
  border-radius: 10px;
  background: #14121d;
  overflow-x: hidden;

  /* Extra small devices (phones, 600px and down) */
  padding: 5px;
  border: 0px;
}

#bg-image-center {
  height: 280px;
  width: 100%;
  background-position: center;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.popup-content {
  width: 100%;
  height: 100%;
}

.popup-image {
  margin-left: 5px;
  margin-right: 5px;
}

.popup-margin {
  margin-left: 5px;
}

.modal-1 {
  overflow: auto;
  overflow-x: hidden;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

.close {
  font-size: 33px !important;
  font-weight: 300 !important;
  color: white !important;
  opacity: 1 !important;
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(204, 174, 174, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-cards {
  border-radius: 10px;
  background: #252333;
  justify-content: center;
  align-items: center;
  display: flex;
}
