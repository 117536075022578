.wallettracker-container {
  padding: 0;

  .title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.75rem;
  }

  .page-title {
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0.75rem;
  }

  .page-subtitle {
    color: #636363 !important;
  }

  .mb-6 {
    margin-bottom: 3.5rem;
  }

  .mb-7 {
    margin-bottom: 4rem;
  }

  .wallet-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .btn-outline-danger {
    border: 2px solid #ff553e;
    border-radius: 30px;
    box-sizing: border-box;
    color: #ff553e;
    display: inline-block;
    padding: 11px 35px;
    transition: all 0.3s ease;
    font-size: 15px;
    box-shadow: none;
    outline: 0;
    line-height: 1.5;
    display: flex;
    align-items: center;
    background-color: #1a1d1f;
    white-space: nowrap;

    &:hover {
      color: white;
    }
  }

  .wallet-details {
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;

    .wallet-icon {
      height: 24px;
      width: 24px;
      border-radius: 6px;
      margin-right: 6px;
      flex-shrink: 0;
    }

    .wallet-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: bold;
      line-height: 1.2;
    }
  }

  .wallets-container {
    padding: 12px 0;
    border-top: 1px solid #1a1a1a;
  }

  .searching-bar-container {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paste-container {
    font-size: 12px;
    color: #5a88ff;
    cursor: pointer;

    .copy-icon {
      height: 15px;
      width: 15px;
    }
  }

  .wallet-card {
    background-color: #1a1a1a;
    border-radius: 6px;
    font-size: 16px;
    margin-bottom: 16px;

    .wallet-header {
      padding: 16px 14px;
      border-bottom: 1px solid #272b30;
    }

    .wallet-icon {
      text-decoration: none;
      height: 36px;
      width: 36px;
      border-radius: 8px;
    }

    .balance-info {
      font-weight: bold;

      + .balance-info {
        &::before {
          content: '|';
          margin: 0 4px;
          color: #646464;
        }
      }
    }

    .wallet-body {
      padding: 16px 14px 8px 14px;
      display: flex;
      justify-content: space-between;
    }

    .wallet-footer {
      padding: 0px 14px 8px 14px;
      display: flex;
      justify-content: end;
    }
  }

  .tgl + .tgl-btn {
    width: 120px;
    height: 3rem;
    font-size: 1.125rem;
  }

  .tgl-skewed + .tgl-btn:after,
  .tgl-skewed + .tgl-btn:before {
    line-height: 3rem;
  }

  @media only screen and (max-width: 768px) {
    .btn-outline-danger {
      padding: 10px 20px;
    }

    .page-title {
      font-size: 30px;
    }

    // .trackerdetail-container {
    //     border: 1px solid #272B30;
    //     background-color: #121314;
    //     border-radius: 6px;
    //     padding: 16px 12px;
    // }
  }
}

.searchwallet-container {
  display: flex;
  align-items: center;
  position: relative;

  .search-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searching-btn-chain {
    position: absolute;
    background-color: #1a1d1f;
    right: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin: 0px 8px 0 12px;
    padding: 11px 8px;
    border-radius: 8px;
    border: 0 !important;
    outline: 0 !important;
    color: #6f767e;
    text-align: center;
    font-weight: 800;
  }
  .searching-btn-container {
    position: absolute;
    background-color: #1a1d1f;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 0px 8px 0 12px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 0 !important;
    outline: 0 !important;
    color: #6f767e;
  }

  .searchinput-icon {
    height: 20px;
    width: 20px;
    margin: 0px 12px;
    color: #6f767e;
  }

  .search-input {
    // border: none;
    // outline: none;
    font-size: 16px;
    border: 0;
    background-color: #272b30;
    color: #6f767e;
    line-height: 1.5;
    width: 100%;
    border-radius: 12px;
    padding-left: 20px !important;
    padding-right: 52px !important;

    &.size-large {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }

    &.size-small {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &::placeholder {
      color: #6f767e;
      font-size: 16px;
    }
  }

  .right-icon-container {
    position: absolute;
    background-color: #1a1d1f;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1.2;
    margin: 0px 8px 0 12px;
    padding: 6px 12px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .wallettracker-container {
    padding: 0 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .wallettracker-container {
    padding: 0 12px;
  }
}

.horizontal-menu-options-container {
  display: flex;
  // justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > * {
    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    font-size: 1rem;
    margin: 0 0.25rem;
  }
}

.chain-list {
  span {
    background-color: #1a1d1f;
    font-size: 13px;
    padding: 11px 8px;
    border-radius: 8px;
    color: #6f767e;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
  }
  .active {
    background-color: #fec60d;
    color: #000000;
    border: 2px solid #000000;
  }
}
