.verify-login-modal {
  .modal-content {
    border-radius: 15px!important;
    border: 2px solid #272b30!important;
    background: #121314!important;
    padding: 2rem;
    h3{
        margin-bottom: 1.5rem;
    }
    img{
        margin-bottom: 1.5rem;
    }

  }
}
