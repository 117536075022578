.blocktabs-container {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 2px;
        top: 54px;
        min-width: 100%;
        background-color: #262626;
    }

    .taboptions-wrapper {
        display: flex;
        list-style-type: none;
        font-size: 16px;
        position: relative;
        padding: 6px 10px 5px 10px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    // margin-bottom: 16px;

    &.tab-primary {
        color: #8A8AA0;

        .tab-option {
            border-bottom: 2px solid transparent;
            z-index: 1;

            &:hover {
                color: #ffffff;
            }


            &.active {
                color: #ffffff;
                border-color: #F9D423;
            }
        }
    }

    .tab-option {
        padding: 16px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        // border-bottom: 2x solid transparent;
        position: relative;

        .count-label {
            // position: absolute;
            margin-left: 4px;
            // right: -0.5rem;
            font-size: 14px;
        }
    }
}

.blocktabs-wrapper {}