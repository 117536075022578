//Wallet Header
.walletdetails-wrapper {
    position: relative;
    height: 74px;
    margin-bottom: 20px;

    .walletdetails-container {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .wallet-img {
            width: 100px;
            height: 100px;
            margin-right: 8px;
            border-radius: 12px;
            object-fit: cover;
        }
    }

    .walletbasic-container {
        display: flex;
        align-items: flex-end;
        overflow: hidden;
    }


    .wallet-name {
        font-size: 22px;
        line-height: 1.2;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .header-actions {
        border: 2px solid #272B30;
        background-color: #1A1D1F;
        border-radius: 16px;
        padding: 8px;
        font-size: 20px;
        display: flex;
        margin-top: 25px;
        flex-shrink: 0;
        position: relative;

        .edit-namecontainer {
            position: absolute;
            top: calc(100% + 10px);
            left: 0;
            border-radius: 8px;
            border: 2px solid #272B30;
            background: #1A1D1F;
            padding: 6px 8px 6px 12px;
            display: flex;
            box-shadow: 0px 13.475686073303223px 53.90274429321289px -40.42706298828125px rgba(31, 47, 70, 0.40);

            &::before {
                content: "";
                width: 0px;
                height: 0px;
                border: 12px solid transparent;
                position: absolute;
            }


            &.top::before {
                left: 4%;
                top: -23px;
                border-bottom: 10px solid #23262F;
            }

        }

        .editname-input {
            border: 0;
            outline: 0;
            padding: 0px 4px 0px 0px !important;
            color: #ffffff;
            font-size: 14px;

            &::placeholder {
                color: #ffffff;
            }
        }

        .update-btn {
            border-radius: 8px;
            color: #000 !important;
            padding: 4px 8px;
            height: 34px !important;
            font-size: 14px !important;
            display: flex;
            align-items: center;
        }

        .share-icon {
            padding: 4px 10px;
            color: #B1B1B1;
            display: flex;

            &.twitter-icon {
                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            &.border-right {
                border-right: 2.5px solid #272B30;
            }

            &:hover {
                color: #F9D423;

                &.twitter-icon {
                    svg {
                        path {
                            color: #F9D423;
                            fill: #F9D423;
                        }
                    }
                }

            }
        }

        @media only screen and (max-width: 768px) {
            .share-icon {
                padding: 6px 8px;

                &.twitter-icon {
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }


        }

    }

    @media only screen and (max-width: 768px) {
        .walletdetails-container {
            align-items: center;
        }

        .walletbasic-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .header-actions {
            font-size: 15px;
            padding: 6px 6px;
        }
    }
}



@media only screen and (max-width: 768px) {
    .walletdetails-wrapper {
        height: 108px;
    }
}